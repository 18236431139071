import { useState, useEffect } from 'react'
import { Row, Col, Form, Space, Button } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { FormFields } from 'common/components'
import { AdvertTypeSelectField } from 'components/AdvertType'

const { TextField, SlugField, NumberField, SwitchField, SelectField } = FormFields;


const TypeOptions = [
  { value: 'string', label: 'Строка' },
  { value: 'number', label: 'Число' },
];


const MainFieldset = ({ form, formDetail, values }) => {
  const [showVariants, setShowVariants] = useState(false);

  useEffect(() => {
    setShowVariants(values?.type === 'string');
  }, [values?.type]);

  const onTypeChange = (value) => {
    setShowVariants(value === 'string');
  }

  const onNameChange = (e) => {
    if (!formDetail) {
      const code = SlugField.slugify(e.target.value, 20);
      form.setFieldsValue({ code })
    }
  }

  const onVariantNameChange = (index) => (e) => {
    const detailVariantsLength = Object.keys(formDetail?.variants || {}).length;
    if (!formDetail || index >= detailVariantsLength) {
      const variants = form.getFieldValue('variants');
      const code = SlugField.slugify(e.target.value, 20);
      Object.assign(variants[index], { code });
      form.setFieldsValue({ variants })
    }
  }

  return (
    <Row gutter={32}>
      <Col span={16}>
        <AdvertTypeSelectField label="Тип объявления" name="advertType" required={true} />

        <TextField label="Название" name="name" required={true} onChange={onNameChange} />

        <SlugField label="Код" name="code" required={true} maxLength={20} />

        <SelectField label="Тип" name="type" required={true} options={TypeOptions} onChange={onTypeChange} />

        {showVariants &&
          <fieldset>
            <legend>Варианты</legend>
            <Form.List name="variants">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <TextField
                        label={`Вариант ${index + 1}`}
                        name={[name, 'name']}
                        required={true}
                        onChange={onVariantNameChange(index)}
                      />

                      <SlugField label="Код" name={[name, 'code']} required={true} maxLength={20} />

                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Добавить вариант
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </fieldset>}
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
