import { Row, Col } from 'antd'

import { FormFields } from 'common/components'
import { PageTypeField, ObjectTypeField } from 'components/Page'

const { TextField, TextareaField, SwitchField } = FormFields;

const MainFieldset = () => {

  return (
    <>
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <PageTypeField label="Тип" name="pageType" exclude={['page', 'listing']} required={true} disabled={true} />
        </Col>
        <Col span={12}>
          <ObjectTypeField label="Тип объекта" name="objectType" exclude={['page']} required={true} />
        </Col>
      </Row>

      <SwitchField label="Активно" name="isActive" />

      <TextField label="Путь" name="path" required={true} />

      <TextField label="Тайтл" name="title" required={true} />

      <TextField label="H1" name="h1" required={true} />

      <TextareaField label="Дескрипшн" name="description" required={true} />

      <TextareaField label="Текст" name="text" required={false} />
    </>
  );
};

export default MainFieldset;
