import React, { useState, useMemo } from 'react'
import { Upload, Button } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from "classnames"

import { arrayIsEqual, isDict, getAbsoluteUrl } from 'common/utils/helpers'

import './styles.css'


const DraggableUploadListItem = ({ originNode, file }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`fileUpload__draggableListItem ${isDragging ? 'is_dragging' : ''}`}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === 'error' && isDragging ? originNode.props.children : originNode}
    </div>
  );
};


const FileUpload = ({
  className,
  action,
  headers,
  data,
  multiple,
  onChange,
  fileList,
  accept,
  size = 'default',
  uploadText = 'Загрузить'
}) => {
  const [initialFileList, setInitialFileList] = useState([]);
  const [files, setFiles] = useState([]);

  const memoFileList = useMemo(() => {
    let _tmpList = fileList ? JSON.parse(JSON.stringify(fileList)) : [];
    if (!Array.isArray(_tmpList)) {
      if (isDict(_tmpList)) {
        _tmpList = [_tmpList];
      } else {
        _tmpList = [];
      }
    }
    return _tmpList;
  }, [fileList]);

  if (memoFileList && !arrayIsEqual(initialFileList, memoFileList)) {
    const _files = memoFileList.map(item => {
      item.uid = item.pk;
      item.origUrl = item.url;
      item.name = item.url.split('\\').pop().split('/').pop();
      item.url = getAbsoluteUrl(item.url);
      return item;
    });

    setFiles(_files);
    setInitialFileList(memoFileList);
  }


  const _onChange = (changedFiles) => {
    if (onChange) {
      const _files = changedFiles.reduce((res, item) => {
        if (item.pk) {
          res.push({ pk: item.pk, url: item.origUrl })
        }
        return res;
      }, []);

      const value = multiple ? _files : _files.length > 0 ? _files[0] : null;
      onChange(value);
    }
  }

  const getReponseFileItem = (file) => {
    if (file.response?.pk) {
      file.uid = file.response.pk;
      file.pk = file.response.pk;
      file.url = getAbsoluteUrl(file.response.url);
      file.origUrl = file.response.url;
    }
    return file;
  }

  const handleChange = (info) => {
    let changedFiles = [];
    if (multiple) {
      changedFiles = info.fileList.map(file => getReponseFileItem(file));
    } else {
      if (info.file.status !== 'removed') {
        changedFiles = [getReponseFileItem(info.file)]
      }
    }
    setFiles(changedFiles);

    const isUploading = changedFiles.some(file => file.status === 'uploading');
    if (!isUploading) {
      _onChange(changedFiles);
    }
  }


  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = files.findIndex((i) => i.uid === active.id);
      const overIndex = files.findIndex((i) => i.uid === over?.id);
      const newFiles = arrayMove(files, activeIndex, overIndex);
      setFiles(newFiles);
      _onChange(newFiles);
    }
  }

  const cx = classNames([
    "fileUpload",
    `fileUpload_${size}`,
    multiple && 'fileUpload_multiple',
    className
  ]);

  return (
    <div className={cx}>
      <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
        <SortableContext items={files.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
          <Upload
            accept={accept}
            multiple={multiple}
            fileList={files}
            action={action}
            headers={headers}
            data={data}

            onChange={handleChange}

            itemRender={(originNode, file) => (
              <DraggableUploadListItem
                originNode={originNode}
                file={file}
              />
            )}
          >
            {(multiple || files.length === 0) &&
              <Button icon={<UploadOutlined />}>{uploadText}</Button>
            }
          </Upload>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default FileUpload
