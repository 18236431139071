import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'common/layout'
import { OnOff } from 'common/components'

import ServiceApi, { pkField } from 'store/api/serviceApi'
import { ServiceActions } from 'store/features/serviceSlice'
import { ServiceForm, ServiceDetail, ServiceList } from 'components/Service'


const ServiceDetailPage = () => {
  const { advertTypePK, servicePK } = useParams();

  const detailPK = servicePK;

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = ServiceApi.endpoints.getServiceDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ServiceApi.endpoints.updateService.useMutation();
  const [apiDelete, apiDeleteProps] = ServiceApi.endpoints.deleteService.useMutation();

  const detail = apiGetDetailProps.data;

  const onEdit = () => {
    dispatch(ServiceActions.openForm({ name: 'editForm', pk: detailPK }))
  };

  const activeSwitch = (
    <OnOff
      name="Service"
      detail={detail}
      pkField={pkField}
      fieldName="isActive"
      apiUpdate={apiUpdate}
      apiUpdateProps={apiUpdateProps}
    />
  );

  const detailTitle = detail?.name ? `«${detail?.name}»` : '';

  return (
    <DetailPage
      title={`Услуга ${detailTitle}`}
      detailPK={detailPK}
      detail={detail}

      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      actions={[
        { label: 'Активно', component: activeSwitch },
        { icon: 'edit', label: 'Редактировать', type: 'primary', onClick: () => onEdit() },
      ]}
    >
      <ServiceDetail detail={detail} />

      <ServiceList title="Подуслуги" baseFilters={{ parent: servicePK }} />
      <ServiceForm showGoToDetailButton={false} initials={{ advertType: parseInt(advertTypePK), parent: parseInt(servicePK) }} parent={servicePK} />
    </DetailPage>
  )
}

export default ServiceDetailPage
