import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import AdvertApi, { pkField, getDetailUrl } from 'store/api/advertApi'
import { AdvertActions } from 'store/features/advertSlice'
import { useFeatureTypeList } from 'store/api/featureTypeApi'
import { usePriceTypeList } from 'store/api/priceTypeApi'
import { useServiceList } from 'store/api/serviceApi'

import MainFieldset from './MainFieldset'
import MediaFieldset from './MediaFieldset'
import ContactsFieldset from './ContactsFieldset'
import FeaturesFieldset from './FeaturesFieldset'
import PricesFieldset from './PricesFieldset'
import ServicesFieldset from './ServicesFieldset'


const Fieldsets = ({ site, advertType, defaultActiveKey }) => {
  const { form, formDetail, values } = useContext(Form.FormContext);

  const [features] = useFeatureTypeList({ advertType }, advertType ? null : []);
  const [priceTypes] = usePriceTypeList({ advertType }, advertType ? null : []);
  const [services] = useServiceList({ advertType }, advertType ? null : []);

  let fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset form={form} formDetail={formDetail} values={values} />,
      requiredFields: ['advertType', 'city', 'name', 'text'],
    },
    {
      key: 'media',
      label: 'Фото и видео',
      children: <MediaFieldset />,
    },
    {
      key: 'contacts',
      label: 'Контакты',
      children: <ContactsFieldset />,
      requiredFields: ['contactPhone'],
    },
  ];

  if (features?.length) {
    fieldsets.push({
      key: 'features',
      label: 'Свойства',
      children: <FeaturesFieldset features={features} />,
    })
  }

  if (priceTypes?.length) {
    fieldsets.push({
      key: 'prices',
      label: 'Цены',
      children: <PricesFieldset priceTypes={priceTypes} />,
    })
  }

  if (services?.length) {
    fieldsets.push({
      key: 'services',
      label: 'Услуги',
      children: <ServicesFieldset services={services} />,
    })
  }

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const AdvertForm = ({ site, advertType, initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.advert);

  const [apiGetFormDetail, apiGetFormDetailProps] = AdvertApi.endpoints.getAdvertFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = AdvertApi.endpoints.updateAdvert.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    console.log('values', values);
    const contactPhone = values?.contactPhone?.value;
    const data = { ...values, contactPhone, site, advertType };
    console.log('data', data);
    apiUpdate(data);
  }

  const initialValues = {
    isEnabled: true,
    ...initials
  }

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  }

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  }

  return (
    <DrawerModelForm
      name="Advert"
      titleNew="Новое объявление"
      titleEdit={`${formDetail?.name}`}

      open={activeForm === 'editForm'}
      closeFormAction={AdvertActions.closeForm()}
      invalidateTags={[{ type: "AdvertList" }, { type: "AdvertDetail" }]}

      {...formProps}
    >
      <Fieldsets site={site} advertType={advertType} defaultActiveKey={['main']} />
    </DrawerModelForm>
  )
}


export default AdvertForm
