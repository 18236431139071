import { Row, Col } from 'antd'

import { Details, Image } from 'common/components'
import { CountryValue } from 'components/Country'
import { CityValue } from 'components/City'


const SiteDetail = ({ detail }) => {
  const logoUrl = detail?.logo?.url;
  const faviconUrl = detail?.favicon?.url;
  const watermarkImageUrl = detail?.watermarkImage?.url;

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Details labelWidth={150}>
          <Details.Item label="Домен">{detail?.domain}</Details.Item>
          <Details.Item label="Скрытый домен">{detail?.hiddenDomain}</Details.Item>
          <Details.Item label="Название">{detail?.name}</Details.Item>
        </Details>

        <Details title="Гео" labelWidth={150}>
          <Details.Item label="Страна"><CountryValue detail={detail?.country} /></Details.Item>
          <Details.Item label="Город"><CityValue detail={detail?.city} /></Details.Item>
          <Details.Item label="Валюта">{detail?.currency}</Details.Item>
        </Details>

        <Details title="Иконки" labelWidth={150}>
          <Details.Item label="Логотип">{logoUrl && <Image src={logoUrl} height={32} />}</Details.Item>
          <Details.Item label="Фавиконка">{faviconUrl && <Image src={faviconUrl} width={32} height={32} />}</Details.Item>
        </Details>

        <Details title="Мета-теги и скрипты" labelWidth={150}>
          <Details.Item label="Мета-теги">{detail?.meta}</Details.Item>
          <Details.Item label="Скрипты в шапке">{detail?.scriptsTop}</Details.Item>
          <Details.Item label="Скрипты в футере">{detail?.scriptsBottom}</Details.Item>
          <Details.Item label="robots.txt">{detail?.robots}</Details.Item>
        </Details>

        <Details title="Контакты" labelWidth={150}>
          <Details.Item label="Email">{detail?.contactEmail}</Details.Item>
          <Details.Item label="Телефон">{detail?.contactPhone}</Details.Item>
          <Details.Item label="Whatsapp">{detail?.contactWhatsapp}</Details.Item>
          <Details.Item label="Telegram">{detail?.contactTelegram}</Details.Item>
        </Details>

        <Details title="Водяной знак" labelWidth={150}>
          <Details.Item label="Позиция">{detail?.watermarkPosition}</Details.Item>
          <Details.Item label="Водяной знак: текст">{detail?.watermarkText}</Details.Item>
          <Details.Item label="Водяной знак: картинка">{watermarkImageUrl && <Image src={watermarkImageUrl} height={32} />}</Details.Item>
        </Details>
      </Col>
    </Row>
  )
}

export default SiteDetail
