import { useMemo } from 'react'
import { Row, Col, Tree } from 'antd'
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import { Player } from 'video-react'
import 'video-react/dist/video-react.css'

import { getAbsoluteUrl, getThumbnailUrl } from 'common/utils/helpers'
import { Details, HtmlText } from 'common/components'
import { ClientValue } from 'components/Client'
import { CityValue } from 'components/City'
import { DistrictValue } from 'components/District'
import { useFeatureTypeList } from 'store/api/featureTypeApi'
import { usePriceTypeList } from 'store/api/priceTypeApi'
import { useServiceList } from 'store/api/serviceApi'

/*
"site", 
"advertType"

"isActive", "status", "statusComment", "isVerified", "isMark", "isVip", "activeUntil",
"raiseAuto", "raisePeriod", "raiseCheckDate", "markAuto", "markCheckDate", "vipAuto", "vipCheckDate",

"changedFields", "createdAt", "changedAt",

*/

const getServiceTree = (serviceList, services) => {
  return serviceList.reduce((res, item) => {
    if (item.children?.length) {
      const children = getServiceTree(item.children, services);
      if (children.length) {
        res.push({ ...item, key: item.code, children });
      }
    } else if (item.code in services) {
      res.push({ ...item, key: item.code, price: services[item.code] });
    }
    return res;
  }, [])
}


const AdvertDetail = ({ detail }) => {
  const photos = (detail?.photos || []).map(item => ({
    original: getThumbnailUrl(item.pk, 800, 800),
    thumbnail: getThumbnailUrl(item.pk, 50, 50),
    fullscreen: getAbsoluteUrl(item.url),
  }));
  const videos = detail?.videos || [];

  const hasMedia = photos.length > 0 || videos.length > 0;

  const adertType = detail?.advertType;

  const [featureTypes] = useFeatureTypeList({ adertType }, !adertType ? [] : null);
  const features = detail?.features || {};

  const [priceTypes] = usePriceTypeList({ adertType }, !adertType ? [] : null);
  const prices = detail?.prices || {};

  const services = detail?.services;
  const serviceCodes = Object.keys(services || {});
  const [serviceList] = useServiceList({ adertType, code__in: serviceCodes }, (!adertType || serviceCodes.length === 0) ? [] : null);

  const serviceTree = useMemo(()=> {
    return getServiceTree(serviceList, services || {});
  }, [serviceList, services]);  


  return (
    <Row gutter={16}>
      {hasMedia &&
        <Col span={8}>
          <Details labelWidth={150}>
            <Details.Item>
              <div style={{ width: '100%', overflow: 'hidden' }}>
                {photos.length > 0 && <ImageGallery items={photos} showPlayButton={false} slideOnThumbnailOver={true} />}
              </div>
            </Details.Item>
          </Details>

          {videos.length > 0 &&
            <Details labelWidth={150}>
              <Details.Item>
                <div>
                  {videos.map(video => <Player key={video.url} src={getAbsoluteUrl(video.url)} />)}
                </div>
              </Details.Item>
            </Details>}
        </Col>}

      <Col span={hasMedia ? 16 : 24}>
        <Details labelWidth={150}>
          <Details.Item label="Клиент"><ClientValue detail={detail?.user} /></Details.Item>
          <Details.Item label="Город"><CityValue detail={detail?.city} /></Details.Item>
          <Details.Item label="Район"><DistrictValue detail={detail?.district} /></Details.Item>
          <Details.Item label="Название">{detail?.name}</Details.Item>
        </Details>

        <Details title="Контакты" labelWidth={150}>
          <Details.Item label="Телефон">{detail?.contactPhone}</Details.Item>
          <Details.Item label="Whatsapp">{detail?.contactWhatsapp}</Details.Item>
          <Details.Item label="Telegram">{detail?.contactTelegram}</Details.Item>
        </Details>

        <Details title="Описание" labelWidth={150}>
          <Details.Item><HtmlText text={detail?.text} /></Details.Item>
        </Details>

        {featureTypes.length > 0 &&
          <Details title="Свойства" labelWidth={150}>
            {featureTypes.map(feature => {
              const value = features[feature.code];
              return (
                <Details.Item key={feature.code} label={feature.name}>{value}</Details.Item>
              )
            })}
          </Details>}

        {priceTypes.length > 0 &&
          <Details title="Цены" labelWidth={150}>
            {priceTypes.map(item => {
              const value = prices[item.code];
              return (
                <Details.Item key={item.code} label={item.name}>{value}</Details.Item>
              )
            })}
          </Details>}

        {serviceTree.length > 0 &&
          <Details title="Услуги" labelWidth={150}>
            <Tree
              selectable={false}
              autoExpandParent={true}
              defaultExpandAll={true}
              treeData={serviceTree}
              titleRender={service => {
                if (service.children?.length) return service.name;
                return (
                  <Row gutter={16} align="middle">
                    <Col span={8}>
                      <nobr>{service.name}{service.price ? ': ': ''}</nobr>
                      <nobr>{service.price}</nobr>
                    </Col>
                  </Row>
                )
              }}
            />
          </Details>}
      </Col>

      <Col span={16}>

      </Col>
    </Row>
  )
}

export default AdvertDetail
