import { Row, Col } from 'antd'

import { FormFields } from 'common/components'
import { AdvertTypeSelectField } from 'components/AdvertType'

const { TextField, SlugField, NumberField, SwitchField } = FormFields;


const MainFieldset = ({ form, formDetail }) => {
  const onNameChange = (e) => {
    if (!formDetail) {
      const code = SlugField.slugify(e.target.value, 20);
      form.setFieldsValue({ code })
    }
  }

  return (
    <Row gutter={32}>
      <Col span={16}>
        <AdvertTypeSelectField label="Тип объявления" name="advertType" required={true} />

        <TextField label="Название" name="name" required={true} onChange={onNameChange} />

        <SlugField label="Код" name="code" required={true} maxLength={200} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  );
};

export default MainFieldset;
