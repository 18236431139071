import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { dictToArray, sortArrayOfDict } from 'common/utils/helpers'
import { DrawerModelForm, Form } from 'common/components'
import FeatureTypeApi, { pkField, getDetailUrl } from 'store/api/featureTypeApi'
import { FeatureTypeActions } from 'store/features/featureTypeSlice'

import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const { form, formDetail, values } = useContext(Form.FormContext);

  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset form={form} formDetail={formDetail} values={values} />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const FeatureTypeForm = ({ initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.featureType);

  const [apiGetFormDetail, apiGetFormDetailProps] = FeatureTypeApi.endpoints.getFeatureTypeFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = FeatureTypeApi.endpoints.updateFeatureType.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    if (values.type === 'string') {
      values.variants = (values.variants || []).reduce((res, item) => {
        res[item.code] = item.name;
        return res;
      }, {});
    }

    apiUpdate(values);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    const variants = dictToArray(detail.variants || {}, 'code', 'name');
    return {
      ...detail,
      variants: sortArrayOfDict(variants, 'name'),
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="FeatureType"
      titleNew="Новый тип свойства"
      titleEdit={`${formDetail?.name}`}

      open={activeForm === 'editForm'}
      closeFormAction={FeatureTypeActions.closeForm()}
      invalidateTags={[{ type: "FeatureTypeList" }, { type: "FeatureTypeDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  );
}


export default FeatureTypeForm
