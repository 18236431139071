import { DetailValue } from 'common/components'
import { getDetailUrl } from 'store/api/pageApi'
import { PageTypeField, ObjectTypeField } from 'components/Page'


const PageValue = ({ detail, asLink = true, isBack = true, showType = true }) => {
  let type = '';
  if (showType) {
    type = PageTypeField.TYPE[detail.pageType] || '';
    if (detail.objectType !== 'page') {
      type += `-${ObjectTypeField.OBJECT_TYPE[detail.objectType] || ''}`
    }
  }

  return (
    <DetailValue className="flex" url={asLink && getDetailUrl(detail)} isBack={isBack}>
      <div>
        {detail?.path}
        {showType && <div className="secondary">Тип: {type || '-'}</div>}
      </div>
    </DetailValue>
  );
};


export default PageValue
