import { useParams, Outlet } from "react-router-dom"

import { SiteSider } from 'components/Site'


const SiteLayout = () => {
  const { sitePK } = useParams();

  return (
    <>
      <SiteSider detailPK={sitePK} />

      <Outlet />
    </>
  )
}

export default SiteLayout;
