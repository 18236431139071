import { useEffect, useState } from 'react'
import { Form, InputNumber } from 'antd'

import { usePriceTypeList } from 'store/api/priceTypeApi'


const PricesInput = ({ filterParams, items, value, onChange }) => {
  const [priceTypes] = usePriceTypeList(filterParams, items);

  const [checkedPrices, setCheckedPrices] = useState([]);

  useEffect(() => {
    setCheckedPrices({ ...value });
  }, [value]);

  const handleChange = (item, price) => {
    const newValue = { ...checkedPrices };
    if (typeof price === 'number') {
      newValue[item.code] = price
    } else {
      delete newValue[item.code];
    }

    setCheckedPrices(newValue);
    onChange && onChange(newValue)
  }

  return (
    priceTypes.map(item =>
      <div key={`price-${item.code}`} className="ant-form-item">
        <div className="ant-col ant-form-item-label">
          <label title={item.name}>{item.name}</label>
        </div>
        <InputNumber
          placeholder={item.name}
          size="large"
          value={checkedPrices[item.code]}
          onChange={value => handleChange(item, value)}
        />
      </div>
    )
  )
}


const PricesField = ({ filterParams, items, name, label, required }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <PricesInput filterParams={filterParams} items={items} />
    </Form.Item>
  )
}

export default PricesField
