import { Row, Col } from 'antd'

import { FormFields } from 'common/components'

const { TextField, SlugField, SwitchField } = FormFields;


const MainFieldset = () => {
  return (
    <Row gutter={32}>
      <Col span={16}>
        <TextField label="Название" name="name" required={true} />

        <SlugField label="Код" name="code" required={true} maxLength={20} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />
      </Col>
    </Row>
  );
};

export default MainFieldset;
