import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import ClientApi, { pkField } from 'store/api/clientApi'
import { ClientActions } from 'store/features/clientSlice'

import { ClientValue } from 'components/Client'


const DefaultColumnList = ['email', 'is_active', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('is_active', 'Активно', SmartFilter.FilterBool),
];


const ClientList = ({ title, columnList, baseFilters, filterParams, onFilterChange, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = ClientApi.endpoints.getClientList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ClientApi.endpoints.updateClient.useMutation();
  const [apiDelete, apiDeleteProps] = ClientApi.endpoints.deleteClient.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = ClientApi.endpoints.batchDeleteClient.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item) => dispatch(ClientActions.openForm({ name: 'editForm', pk: item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const onPasswordChange = (item) => dispatch(ClientActions.openForm({ name: 'passwordChangeForm', pk: item[pkField] }));

  const fields = {
    email: {
      title: 'Пользователь',
      sorter: true,
      render: (val, item) => (<ClientValue detail={item} asLink={false} />),
    },
    is_active: TableColumns.getColumnOnOff({ title: 'Активно', name: 'Client', fieldName: 'is_active', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'password', onClick: () => onPasswordChange(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "ClientList" }]}

      baseFilters={baseFilters}
      filterQueryKey='client'
      filterFields={showFilters ? FilterFields : null}
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={true}
      hidePagination={false}
    />
  );
}

ClientList.FilterFields = FilterFields;

export default ClientList;
