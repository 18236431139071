import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { Page } from 'common/layout'
import { SmartFilter } from 'common/components'

import { SeoTemplateActions } from 'store/features/seoTemplateSlice'
import { SeoTemplateList, SeoTemplateForm } from 'components/SeoTemplate'


const SeoTemplateListPage = () => {
  const { sitePK } = useParams();
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(SeoTemplateActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  const baseFilters = { site: sitePK };

  return (
    <Page
      title="Seo-шаблоны"

      actions={[
        { icon: 'add', label: 'Добавить', type: 'primary', onClick: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="seoTemplate" onChange={onFilterChange} fields={SeoTemplateList.FilterFields} />}
    >
      <SeoTemplateList
        baseFilters={baseFilters}
        showFilters={false} filterParams={params} onFilterChange={onFilterChange}
      />

      <SeoTemplateForm site={sitePK} />
    </Page >
  )
}

export default SeoTemplateListPage
