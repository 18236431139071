import {
  ExclamationCircleOutlined, FieldTimeOutlined, CloseCircleOutlined,
  CheckCircleOutlined, StopOutlined, PlayCircleOutlined, PauseCircleOutlined, SafetyCertificateOutlined, TagOutlined
} from "@ant-design/icons"
import dayjs from 'dayjs'

import { Status, SvgIcon } from 'common/components'

import './styles.css'


const STATUS_LIST = [
  ['new', 'Новое'],
  ['checking', 'На проверке'],
  ['checked', 'Проверено'],
  ['changes_rejected', 'Отклонено'],
  ['banned', 'Забанено'],
  ['deleted', 'Удалено'],
]

const STATUS = STATUS_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {});

const ICONS = {
  'new': <ExclamationCircleOutlined />,
  'checking': <FieldTimeOutlined />,
  'checked': <CheckCircleOutlined />,
  'changes_rejected': <CloseCircleOutlined />,
  'banned': <StopOutlined />,
  'deleted': <StopOutlined />,
}


const AdvertStatus = ({ detail, style, showStatuses = false }) => {
  let activeUntil = '', markCheckDate = '', vipCheckDate = '';
  if (showStatuses) {
    activeUntil = dayjs(detail?.activeUntil).format('DD.MM.YYYY HH:mm');
    markCheckDate = dayjs(detail?.markCheckDate).format('DD.MM.YYYY HH:mm');
    vipCheckDate = dayjs(detail?.vipCheckDate).format('DD.MM.YYYY HH:mm');
  }

  return (
    <>
      <Status status={detail?.status} statuses={STATUS} icons={ICONS} style={style} />
      {showStatuses && !detail?.isDeleted &&
        <div>
          <div className="advertStatuses">
            <div title={`Активно до ${activeUntil}`}>
              {detail?.isActive ?
                <PlayCircleOutlined style={{ color: 'var(--ant-success-color)' }} /> :
                <PauseCircleOutlined style={{ color: 'var(--ant-error-color)' }} />}
            </div>
            {detail?.verified && <SafetyCertificateOutlined />}
            {detail?.isMark && <div title={`Последнее выделение ${markCheckDate}`}><TagOutlined /></div>}
            {detail?.isVip && <div title={`Последнее vip ${vipCheckDate}`}><SvgIcon icon="vip" /></div>}
          </div>
        </div>}
    </>

  );
};

AdvertStatus.STATUS_LIST = STATUS_LIST;
AdvertStatus.STATUS = STATUS;


export default AdvertStatus
