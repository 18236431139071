import { Row, Col } from 'antd'

import { FormFields } from 'common/components'

const { TextField, ImageUploadField, SwitchField } = FormFields;


const MainFieldset = () => {
  return (
    <Row gutter={32}>
      <Col span={24}>
        <TextField label="Email" name="email" autoComplete="off" required={true} />

        <Row gutter={32} style={{ marginBottom: '24px' }}>
          <Col span={8}>
            <ImageUploadField
              label="Фотография" name="photo" data={{ category: 'user' }}
              required={false} multiple={false} size="large"
            />
          </Col>

          <Col span={16}>
            <TextField label="Имя" name="first_name" required={false} />

            <TextField label="Фамилия" name="last_name" required={false} />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={8}>
            <SwitchField label="Активно" name="is_active" />
          </Col>

          <Col span={16}>
            <SwitchField label="Суперпользователь" name="is_superuser" />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MainFieldset;
