import { dictToArray, sortArrayOfDict } from 'common/utils/helpers'
import { FormFields } from 'common/components'
import { pkField, useFeatureTypeList } from 'store/api/featureTypeApi'

const { TextField, NumberField, SelectField } = FormFields;


const FeatureField = ({ name, featureType }) => {
  const variants = sortArrayOfDict(dictToArray(featureType.variants || {}, 'code', 'name'), 'name');

  if (variants.length) {
    const options = variants.map(({ code, name }) => ({ value: code, label: name }));
    return (
      <SelectField label={featureType.name} name={[name, featureType.code]} required={false} options={options} />
    )
  }

  if (featureType.type === 'number') {
    return (
      <NumberField label={featureType.name} name={[name, featureType.code]} required={false} />
    )
  }

  return (
    <TextField label={featureType.name} name={[name, featureType.code]} required={false} />
  )
}


const FeaturesField = ({ name, filterParams, items }) => {
  const [features] = useFeatureTypeList(filterParams, items);

  return features.map(feature => <FeatureField key={feature[pkField]} name={name} featureType={feature} />)
}

export default FeaturesField;
