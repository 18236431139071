import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import AdvertTypeApi, { pkField, getDetailUrl } from 'store/api/advertTypeApi'
import { AdvertTypeActions } from 'store/features/advertTypeSlice'
import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const AdvertTypeForm = ({ initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.advertType);

  const [apiGetFormDetail, apiGetFormDetailProps] = AdvertTypeApi.endpoints.getAdvertTypeFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = AdvertTypeApi.endpoints.updateAdvertType.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="AdvertType"
      titleNew="Новый тип объявлений"
      titleEdit={`${formDetail?.name}`}

      open={activeForm === 'editForm'}
      closeFormAction={AdvertTypeActions.closeForm()}
      invalidateTags={[{ type: "AdvertTypeList" }, { type: "AdvertTypeDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  );
}


export default AdvertTypeForm
