import { DetailValue } from 'common/components'
import { getDetailUrl } from 'store/api/featureTypeApi'


const FeatureTypeValue = ({ detail, asLink = true, isBack = true, replace = false }) => {
  return (
    <DetailValue className="flex" url={asLink && getDetailUrl(detail)} isBack={isBack} replace={replace}>
      {detail?.name}
    </DetailValue>
  );
};


export default FeatureTypeValue
