import { Row, Col } from 'antd'

import { Details } from 'common/components'


const ServiceDetail = ({ detail }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Details>
          <Details.Item label="Название">{detail?.name}</Details.Item>

          <Details.Item label="Код">{detail?.code}</Details.Item>

          <Details.Item label="Сортировка">{detail?.sort}</Details.Item>
        </Details>
      </Col>
    </Row>
  );
}

export default ServiceDetail
