import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems, useLoadedDetail } from 'common/utils/useHooks'


const baseApiUrl = 'page/';

export const pkField = 'pk';

export const getDetailUrl = (detail = null, pk = null) => {
  if (!detail && !pk) return '';
  const sitePK = detail.site?.pk || detail.site;
  const detailPK = pk || detail[pkField];
  return `/sites/${sitePK}/pages/${detailPK}/`
}


const PageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPageList: getList(builder, baseApiUrl, 'PageList'),
    getPageSimpleList: getList(builder, baseApiUrl, 'PageSimpleList', { simple: true }),

    getPageDetail: getDetail(builder, baseApiUrl, 'PageDetail'),
    getPageFormDetail: getDetail(builder, baseApiUrl, 'PageFormDetail', { forEdit: true }),

    updatePage: update(builder, baseApiUrl, pkField),

    deletePage: remove(builder, baseApiUrl),
    batchDeletePage: batchRemove(builder, baseApiUrl),
  }),
});

export function usePageSimpleList(filterParams, items) {
  return useLoadedItems(PageApi.endpoints.getPageSimpleList, filterParams, items);
}

export function usePageDetail(pk, detail) {
  return useLoadedDetail(PageApi.endpoints.getPageDetail, pk, detail);
}

export default PageApi;
