import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import SeoTemplateApi, { pkField, getDetailUrl } from 'store/api/seoTemplateApi'
import { SeoTemplateActions } from 'store/features/seoTemplateSlice'
import { ObjectTypeField } from 'components/Page'
import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const SeoTemplateForm = ({ site, initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.seoTemplate);

  const [apiGetFormDetail, apiGetFormDetailProps] = SeoTemplateApi.endpoints.getSeoTemplateFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = SeoTemplateApi.endpoints.updateSeoTemplate.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    const data = { ...values, site };
    apiUpdate(data);
  };

  const initialValues = {
    isActive: true,
    pageType: 'page',
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  const objectType = ObjectTypeField.OBJECT_TYPE[formDetail?.objectType] || '';

  return (
    <DrawerModelForm
      name="SeoTemplate"
      titleNew="Новый seo-шаблон"
      titleEdit={`Шаблон для «${objectType}»`}

      open={activeForm === 'editForm'}
      closeFormAction={SeoTemplateActions.closeForm()}
      invalidateTags={[{ type: "SeoTemplateList" }, { type: "SeoTemplateDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  )
}

export default SeoTemplateForm
