import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import CityApi, { pkField, getDetailUrl } from 'store/api/cityApi'
import { CityActions } from 'store/features/citySlice'

import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const { formDetail } = useContext(Form.FormContext);

  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset formDetail={formDetail} />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const CityForm = ({ initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.city);

  const [apiGetFormDetail, apiGetFormDetailProps] = CityApi.endpoints.getCityFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CityApi.endpoints.updateCity.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="City"
      titleNew="Новый город"
      titleEdit={`${formDetail?.name}`}

      open={activeForm === 'editForm'}
      closeFormAction={CityActions.closeForm()}
      invalidateTags={[{ type: "CityList" }, { type: "CityDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  );
}


export default CityForm
