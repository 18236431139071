import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'advertfilterfield/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/advertfilterfields/${detail[pkField]}/`
}


const AdvertFilterFieldApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAdvertFilterFieldList: getList(builder, baseApiUrl, 'AdvertFilterFieldList'),
    getAdvertFilterFieldSimpleList: getList(builder, baseApiUrl, 'AdvertFilterFieldSimpleList', { simple: true }),

    getAdvertFilterFieldDetail: getDetail(builder, baseApiUrl, 'AdvertFilterFieldDetail'),
    getAdvertFilterFieldFormDetail: getDetail(builder, baseApiUrl, 'AdvertFilterFieldFormDetail', { forEdit: true }),

    updateAdvertFilterField: update(builder, baseApiUrl, pkField),

    deleteAdvertFilterField: remove(builder, baseApiUrl),
    batchDeleteAdvertFilterField: batchRemove(builder, baseApiUrl),
  }),
});


export default AdvertFilterFieldApi;
