import { Page } from 'common/layout'


const HomePage = () => {
  return (
    <Page
      title="Рабочий стол"
    >

    </Page >
  );
};

export default HomePage
