import { Status, FormFields } from 'common/components'

const { SelectField } = FormFields;


const OBJECT_TYPE_LIST = [
  ['page', 'Статическая страница'],
  ['search', 'Страница поиска'],
  ['advert', 'Объявление'],
  ['adverttype', 'Тип объявления'],
  ['country', 'Страна'],
  ['city', 'Город'],
  ['district', 'Район'],
  ['service', 'Услуга'],
]

const OBJECT_TYPE = OBJECT_TYPE_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {});

export function ObjectType({ detail, style }) {
  return (
    <Status status={detail?.objectType} statuses={OBJECT_TYPE} style={style} />
  )
}


const OBJECT_TYPE_OPTIONS = OBJECT_TYPE_LIST.map(item => ({ label: item[1], value: item[0] }));

const ObjectTypeField = ({ label, name, required, exclude, only, ...otherProps }) => {
  let options = OBJECT_TYPE_OPTIONS;
  if (only?.length) {
    options = OBJECT_TYPE_OPTIONS.filter(item => only.includes(item.value));
  } else if (exclude?.length) {
    options = OBJECT_TYPE_OPTIONS.filter(item => !exclude.includes(item.value));
  }

  return (
    <SelectField label={label} name={name} required={required} options={options} {...otherProps} />
  )
}


ObjectTypeField.OBJECT_TYPE_LIST = OBJECT_TYPE_LIST;
ObjectTypeField.OBJECT_TYPE = OBJECT_TYPE;

export default ObjectTypeField
