import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'category/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  const sitePK = detail.site?.pk || detail.site;
  return `/sites/${sitePK}/categories/${detail[pkField]}/`
}


const CategoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategoryList: getList(builder, baseApiUrl, 'CategoryList'),
    getCategorySimpleList: getList(builder, baseApiUrl, 'CategorySimpleList', { simple: true }),

    getCategoryDetail: getDetail(builder, baseApiUrl, 'CategoryDetail'),
    getCategoryFormDetail: getDetail(builder, baseApiUrl, 'CategoryFormDetail', { forEdit: true }),

    updateCategory: update(builder, baseApiUrl, pkField),

    deleteCategory: remove(builder, baseApiUrl),
    batchDeleteCategory: batchRemove(builder, baseApiUrl),
  }),
});

export function useCategorySimpleList(filterParams, items) {
  return useLoadedItems(CategoryApi.endpoints.getCategorySimpleList, filterParams, items);
}

export function useCategoryList(filterParams, items) {
  return useLoadedItems(CategoryApi.endpoints.getCategoryList, filterParams, items);
}

export default CategoryApi;
