import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from 'store/api/advertApi'


const initialState = {
  ...baseModelState,
}


export const advert = createSlice({
  name: 'advert',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateAdvert);
  },
});

export default advert.reducer;

export const AdvertActions = advert.actions;
