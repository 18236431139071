import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import {
  SettingOutlined, ProfileOutlined, FileOutlined, FileSearchOutlined, ClusterOutlined
} from '@ant-design/icons'

import { getDetailUrl } from 'store/api/siteApi'
import { useAdvertTypeSimpleList, pkField as advertTypePkField } from 'store/api/advertTypeApi'

import './styles.css'



function getItem(label, key, icon, type, children) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}

const getMenuItems = (baseUrl, user, advertTypes) => {
  let items = [
    getItem('Настройки сайта', `${baseUrl}`, <SettingOutlined />),
    //getItem('Рабочий стол', `${baseUrl}`, <GlobalOutlined />)
  ];

  const advertTypeMenuItems = advertTypes.map((advertType) =>
    getItem(advertType.name, `${baseUrl}adverts/${advertType[advertTypePkField]}/`, <ProfileOutlined />)
  );

  if (advertTypes.length > 1) {
    items.push(getItem('Объявления', `${baseUrl}adverttypes/`, <FileOutlined />, 'group', advertTypeMenuItems))
  } else {
    items = items.concat(advertTypeMenuItems);
  }

  items = items.concat([
    getItem('Категории', `${baseUrl}categories/`, <ClusterOutlined />),
    getItem('Страницы', `${baseUrl}pages/`, <FileOutlined />),
    getItem('Seo-шаблоны', `${baseUrl}seotemplates/`, <FileSearchOutlined />),
    //getItem('Настройки сайта', `${baseUrl}settings/`, <SettingOutlined />),
  ]);

  return items;
}


const Navigation = ({ detailPK }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [advertTypes] = useAdvertTypeSimpleList();

  const user = useSelector(state => state.auth.user);
  const baseUrl = getDetailUrl(null, detailPK);

  const allKeys = useMemo(() => {
    let keys = location.pathname.slice(1, -1).split('/').reduce((result, slug) => {
      const before = result.length > 0 ? result[result.length - 1] : '/';
      if (slug === '') {
        result.push(`/`);
      } else {
        result.push(`${before}${slug}/`);
      }
      return result
    }, []);

    if (keys.length > 2) keys = keys.slice(2);
    return keys;
  }, [location.pathname]);

  const onNavigate = ({ key }) => {
    navigate(key);
  }

  const items = getMenuItems(baseUrl, user, advertTypes);

  return (
    <Menu
      className='siteNavigation'
      mode='inline'
      theme='light'
      items={items}
      defaultOpenKeys={allKeys}
      //defaultSelectedKeys={[currentPath, '/tenant/tenant/']}
      selectedKeys={allKeys}
      onClick={onNavigate}
    />
  )
}

export default Navigation;
