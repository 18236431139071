import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { Page } from 'common/layout'
import { SmartFilter } from 'common/components'

import { CategoryActions } from 'store/features/categorySlice'
import { CategoryList, CategoryForm } from 'components/Category'


const CategoryListCategory = () => {
  const { sitePK } = useParams();
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(CategoryActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  const baseFilters = { site: sitePK };

  return (
    <Page
      title="Категории"

      actions={[
        { icon: 'add', label: 'Добавить', type: 'primary', onClick: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="category" onChange={onFilterChange} fields={CategoryList.FilterFields} />}
    >
      <CategoryList
        baseFilters={baseFilters}
        showFilters={false} filterParams={params} onFilterChange={onFilterChange}
      />

      <CategoryForm site={sitePK} />
    </Page >
  )
}

export default CategoryListCategory
