import { Status, FormFields } from 'common/components'

const { SelectField } = FormFields;


const TYPE_LIST = [
  ['page', 'Статическая страница'],
  ['listing', 'Листинг'],
  ['detail', 'Детальная страница'],
]

const TYPE = TYPE_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {});

export function PageType({ detail, style }) {
  return (
    <Status status={detail?.pageType} statuses={TYPE} style={style} />
  )
}


const TYPE_OPTIONS = TYPE_LIST.map(item => ({ label: item[1], value: item[0] }));

const PageTypeField = ({ label, name, required, exclude, ...otherProps }) => {
  const options = exclude?.length ? TYPE_OPTIONS.filter(item => !exclude.includes(item.value)): TYPE_OPTIONS;
  return (
    <SelectField label={label} name={name} required={required} options={options} {...otherProps} />
  )
}

PageTypeField.TYPE_LIST = TYPE_LIST;
PageTypeField.TYPE = TYPE;

export default PageTypeField
