import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'common/layout'
import { SmartFilter } from 'common/components'

import { SiteActions } from 'store/features/siteSlice'
import { SiteList, SiteForm } from 'components/Site'


const SiteListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => { 
    dispatch(SiteActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Сайты"

      actions={[
        { icon: 'add', label: 'Добавить', type: 'primary', onClick: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="country" onChange={onFilterChange} fields={SiteList.FilterFields} />}
    >
      <SiteList showFilters={false} filterParams={params} onFilterChange={onFilterChange} />

      <SiteForm />
    </Page >
  )
}

export default SiteListPage
