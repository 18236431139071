import { Row, Col } from 'antd'

import { Details } from 'common/components'


const AdvertTypeDetail = ({ detail }) => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Details>
          <Details.Item label="Название">{detail?.name}</Details.Item>

          <Details.Item label="Код">{detail?.code}</Details.Item>
        </Details>
      </Col>
    </Row>
  );
}

export default AdvertTypeDetail
