import { Row, Col } from 'antd'

import { FormFields } from 'common/components'
import { CategorySelectField } from 'components/Category'

const { TextField, SlugField, NumberField, SwitchField, ImageUploadField, TextareaField } = FormFields;


const MainFieldset = ({ site, form, formDetail, parent }) => {
  const onNameChange = (e) => {
    if (!formDetail) {
      const code = SlugField.slugify(e.target.value, 100);
      form.setFieldsValue({ code })
    }
  }

  let serviceFilterParams = { site };
  if (parent && parent !== formDetail?.pk) {
    serviceFilterParams['withParent'] = parent
  }

  return (
    <Row gutter={32}>
      <Col span={16}>
        <CategorySelectField
          label="Группа категорий"
          name="parent"
          required={false}
          filterParams={serviceFilterParams}
          exclude={formDetail?.pk ? [formDetail?.pk] : null}
        />

        <Row gutter={16} style={{ marginBottom: '16px' }}>
          <Col span={9}>
            <ImageUploadField
              label="Иконка" name="icon" data={{ category: 'category' }} required={false}
              uploadText="Загрузить" multiple={false}
            />
          </Col>

          <Col span={15}>
            <TextField label="Название" name="name" required={true} maxLength={100} onChange={onNameChange} />

            <SlugField label="Код" name="code" required={true} maxLength={100} />
          </Col>
        </Row>

        <TextareaField label="Тизер" name="teaser" required={false} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  )
}

export default MainFieldset
