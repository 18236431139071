import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import CategoryApi, { pkField, getDetailUrl } from 'store/api/categoryApi'
import { CategoryActions } from 'store/features/categorySlice'
import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey, parent, site }) => {
  const { form, formDetail, values } = useContext(Form.FormContext);

  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset site={site} parent={parent} form={form} formDetail={formDetail} values={values} />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const CategoryForm = ({ site, parent, initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.category);

  const [apiGetFormDetail, apiGetFormDetailProps] = CategoryApi.endpoints.getCategoryFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CategoryApi.endpoints.updateCategory.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    if (values.parent === undefined) values.parent = null;
    const data = { ...values, site };
    apiUpdate(data);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="Category"
      titleNew="Новая услуга"
      titleEdit={`${formDetail?.name}`}

      open={activeForm === 'editForm'}
      closeFormAction={CategoryActions.closeForm()}
      invalidateTags={[{ type: "CategoryList" }, { type: "CategoryDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} parent={parent} site={site} />
    </DrawerModelForm>
  );
}


export default CategoryForm
