import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems, useLoadedDetail } from 'common/utils/useHooks'


const baseApiUrl = 'seotemplate/';

export const pkField = 'pk';

export const getDetailUrl = (detail = null, pk = null) => {
  if (!detail && !pk) return '';
  const sitePK = detail.site?.pk || detail.site;
  const detailPK = pk || detail[pkField];
  return `/sites/${sitePK}/seotemplates/${detailPK}/`
}


const SeoTemplateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSeoTemplateList: getList(builder, baseApiUrl, 'SeoTemplateList'),
    getSeoTemplateSimpleList: getList(builder, baseApiUrl, 'SeoTemplateSimpleList', { simple: true }),

    getSeoTemplateDetail: getDetail(builder, baseApiUrl, 'SeoTemplateDetail'),
    getSeoTemplateFormDetail: getDetail(builder, baseApiUrl, 'SeoTemplateFormDetail', { forEdit: true }),

    updateSeoTemplate: update(builder, baseApiUrl, pkField),

    deleteSeoTemplate: remove(builder, baseApiUrl),
    batchDeleteSeoTemplate: batchRemove(builder, baseApiUrl),
  }),
});

export function useSeoTemplateSimpleList(filterParams, items) {
  return useLoadedItems(SeoTemplateApi.endpoints.getSeoTemplateSimpleList, filterParams, items);
}

export function useSeoTemplateDetail(pk, detail) {
  return useLoadedDetail(SeoTemplateApi.endpoints.getSeoTemplateDetail, pk, detail);
}

export default SeoTemplateApi;
