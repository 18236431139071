import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from 'store/api/serviceApi'


const initialState = {
  ...baseModelState,
}


export const service = createSlice({
  name: 'service',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateService);
  },
});

export default service.reducer;

export const ServiceActions = service.actions;
