import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import PriceTypeApi, { pkField, getDetailUrl } from 'store/api/priceTypeApi'
import { PriceTypeActions } from 'store/features/priceTypeSlice'

import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const { form, formDetail, values } = useContext(Form.FormContext);

  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset form={form} formDetail={formDetail} values={values} />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const PriceTypeForm = ({ initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.priceType);

  const [apiGetFormDetail, apiGetFormDetailProps] = PriceTypeApi.endpoints.getPriceTypeFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = PriceTypeApi.endpoints.updatePriceType.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="PriceType"
      titleNew="Новый тип цены"
      titleEdit={`${formDetail?.name}`}

      open={activeForm === 'editForm'}
      closeFormAction={PriceTypeActions.closeForm()}
      invalidateTags={[{ type: "PriceTypeList" }, { type: "PriceTypeDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  )
}


export default PriceTypeForm
