import { Pagination } from 'antd'

import './styles.css'


const TableFooter = ({
  objectSearchParams,
  allCount,
  pageSize = 10,
  onChange,
}) => {
  const offset = parseInt(objectSearchParams.get('offset') || 0);
  const currentPage = offset / pageSize + 1;
  const fromIndex = offset + 1;
  let toIndex = fromIndex + pageSize;
  if (toIndex > allCount) toIndex = allCount;

  return (
    <div className="tableFooter">
      {allCount > 0 ? 
        <div className="tableFooter__stat">Показано с {fromIndex} по {toIndex} из {allCount}</div>
        :
        <div className="tableFooter__stat">Показано 0</div>
      }

      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={allCount}
        showSizeChanger={false}
        hideOnSinglePage={true}
        onChange={onChange}
      />
    </div>
  );
};

export default TableFooter
