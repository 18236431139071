import classNames from 'classnames'

import './styles.css'


export default function HtmlText({ className, text }) {
  if (!text) return null;

  const cx = classNames(['htmlText', className]);

  return (
    <div className={cx}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}
