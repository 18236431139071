import { DetailValue } from 'common/components'
import { getDetailUrl } from 'store/api/categoryApi'


const CategoryValue = ({ detail, asLink = true, isBack = true }) => {
  return (
    <DetailValue className="flex" url={asLink && getDetailUrl(detail)} isBack={isBack}>
      {detail?.name}
    </DetailValue>
  );
};


export default CategoryValue
