import { SvgIcon } from 'common/components'

import './styles.css'


const PropertyList = ({ mode, items, style, hideEmpty = true, align = 'left' }) => {
  let cls = "propertyList";
  cls += ` propertyList_${align}`;
  if (mode) {
    cls += ` ${mode}`;
  }
  return (
    <div className={cls} style={style}>
      {items.map((item, idx) => {
        if (hideEmpty && !item.value) return null;
        return (
          <div key={`prop${idx}`} className={`propertyListItem ${item.block ? 'propertyListItem_block' : ''}`}>
            {(item.label || item.icon) && <div className={`propertyListItem__lbl ${item.icon ? 'withIcon' : ''}`}>
              {item.icon && <SvgIcon className="propertyListItem__icon" icon={item.icon} />}
              {item.label}
            </div>}
            <div className={`propertyListItem__val propertyListItem__val__size_${item.size}`}>{item.value}</div>
          </div>
        )
      })}
    </div>
  );
};

export default PropertyList;
