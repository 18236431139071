import { FormFields } from 'common/components'

const { TextField, PhoneField } = FormFields;


const ContactsFieldset = () => {
  return (
    <>
      <PhoneField label="Телефон" name="contactPhone" required={true} />

      <TextField label="Whatsapp" name="contactWhatsapp" required={false} />

      <TextField label="Telegram" name="contactTelegram" required={false} />
    </>
  )
}

export default ContactsFieldset
