import { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom"
import { Table as AntTable } from 'antd'

import { getObjectSearchParams, getDataQueryString } from 'common/utils/urls'
import { api } from 'store/api/api'
import TableRawActions from './TableRawActions'
import TableFooter from './TableFooter'

import './styles.css'


function getOrder(field, orderField) {
  if (orderField === field) return 'ascend';
  if (orderField === `-${field}`) return 'descend';
  return false;
}

function getOrderField(field, order) {
  if (order === 'ascend') return field;
  if (order === 'descend') return `-${field}`;
  return false;
}


const Table = ({
  filterQueryKey,
  rowKey,
  filterParams,
  baseFilters,
  fields,
  columnList,
  dataList,

  apiGetList,
  apiGetListProps,
  apiBatchDelete,
  apiUpdateProps,
  apiDeleteProps,
  apiBatchDeleteProps,
  apiBatchUpdateProps,
  invalidateTags,

  rowActions,
  rowSelection,
  hidePagination,
  expandable,
  sticky,
}) => {
  if ((dataList === null || dataList === undefined) && (!apiGetList && !apiGetListProps)) {
    throw new Error('Parameters dataList or apiGetList/apiGetListProps are required!');
  }

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);

  const searchParams = new URLSearchParams(location.search);

  const objectSearchParams = useMemo(() => {
    return getObjectSearchParams(location.search, filterQueryKey);
  }, [location.search, filterQueryKey]);

  const orderField = objectSearchParams.get('ordering');

  const columns = useMemo(() => {
    return columnList.reduce((result, fieldName) => {
      let config = fields[fieldName];
      if (config) {
        result.push({ ...config, key: fieldName, dataIndex: fieldName, sortOrder: getOrder(fieldName, orderField) })
      }
      return result
    }, []);
  }, [columnList, fields, orderField]);

  const onBatchDelete = (selected) => {
    apiBatchDelete && apiBatchDelete(selected);
    setSelected([]);
  }

  function loadData(changeNav = true) {
    const [dataQueryString, searchString] = getDataQueryString(searchParams, objectSearchParams, filterQueryKey);
    if (changeNav) navigate({ search: searchString }, { replace: true });

    apiGetList && apiGetList({ params: dataQueryString, baseFilters });
  }

  const onTableChange = (pagination, filters, sorter) => {
    const ordering = getOrderField(sorter.field, sorter.order);
    if (ordering) {
      objectSearchParams.set('ordering', getOrderField(sorter.field, sorter.order));
    } else {
      objectSearchParams.delete('ordering');
    }

    const offset = (pagination.current - 1) * pagination.pageSize;
    if (offset) {
      objectSearchParams.set('offset', offset);
    } else {
      objectSearchParams.delete('offset');
    }

    loadData();
  };

  const onPagination = (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    if (offset) {
      objectSearchParams.set('offset', offset);
    } else {
      objectSearchParams.delete('offset');
    }

    loadData();
  };

  useEffect(() => {
    loadData(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadData(false);
    // eslint-disable-next-line
  }, [filterParams, baseFilters]);

  useEffect(() => {
    if (apiUpdateProps?.isSuccess || apiDeleteProps?.isSuccess || apiBatchDeleteProps?.isSuccess || apiBatchUpdateProps?.isSuccess) {
      apiUpdateProps?.reset && apiUpdateProps?.reset();
      apiDeleteProps?.reset && apiDeleteProps?.reset();
      apiBatchDeleteProps?.reset && apiBatchDeleteProps?.reset();
      apiBatchUpdateProps?.reset && apiBatchUpdateProps?.reset();
      invalidateTags && dispatch(api.util.invalidateTags(invalidateTags))
      //loadData();
    }
    // eslint-disable-next-line
  }, [
    apiUpdateProps?.isSuccess, apiDeleteProps?.isSuccess,
    apiBatchDeleteProps?.isSuccess, apiBatchUpdateProps?.isSuccess,
    invalidateTags
  ]);

  const _rowActions = rowActions || [{
    title: 'Удалить',
    icon: 'delete',
    type: 'danger',
    action: (selected) => onBatchDelete(selected),
    confirm: true,
  }];

  const dataSource = dataList ? dataList : apiGetListProps.data?.results || [];
  const count = dataList ? dataList.length : apiGetListProps.data?.count || 0;

  return (
    <div className="table">
      <TableRawActions
        rowActions={_rowActions}
        selected={selected}
        clearSelected={() => setSelected([])}
      />

      <AntTable
        tableLayout="auto"
        sticky={sticky}
        columns={columns}
        dataSource={dataSource}
        rowKey={rowKey || "id"}
        loading={apiGetListProps?.isFetching}
        pagination={false}
        onChange={onTableChange}
        expandable={expandable}

        rowSelection={rowSelection && {
          selectedRowKeys: selected,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelected(selectedRowKeys.slice());
          },
        }}
      />

      {!hidePagination &&
        <TableFooter objectSearchParams={objectSearchParams} allCount={count} onChange={onPagination} />}
    </div>
  );
};


export default Table;
