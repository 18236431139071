import { Form } from 'antd'

import { TreeSelect } from 'common/components'
import { pkField, useServiceSimpleList } from 'store/api/serviceApi'


export function ServiceSelect({ filterParams, items, required, exclude = [], ...selectProps }) {
  const [services] = useServiceSimpleList(filterParams, items);

  return (
    <TreeSelect
      treeOptions={services}
      fieldNames={{ label: 'name', value: pkField, children: 'children' }}
      pkField={pkField}
      exclude={exclude}
      {...selectProps}
    />
  )
}


const ServiceSelectField = ({ name, label, required, disabled, filterParams, exclude = [], ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <ServiceSelect required={required} disabled={disabled} exclude={exclude} filterParams={filterParams} {...selectProps} />
    </Form.Item>
  );
}

ServiceSelectField.getValue = (values) => {
  return values;
}

ServiceSelectField.getDisplayValue = (values, config) => {
  return values
};

export default ServiceSelectField
