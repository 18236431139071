import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'


const baseApiUrl = 'advertfilter/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/advertfilters/${detail[pkField]}/`
}


const AdvertFilterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAdvertFilterList: getList(builder, baseApiUrl, 'AdvertFilterList'),
    getAdvertFilterSimpleList: getList(builder, baseApiUrl, 'AdvertFilterSimpleList', { simple: true }),

    getAdvertFilterDetail: getDetail(builder, baseApiUrl, 'AdvertFilterDetail'),
    getAdvertFilterFormDetail: getDetail(builder, baseApiUrl, 'AdvertFilterFormDetail', { forEdit: true }),

    updateAdvertFilter: update(builder, baseApiUrl, pkField),

    deleteAdvertFilter: remove(builder, baseApiUrl),
    batchDeleteAdvertFilter: batchRemove(builder, baseApiUrl),
  }),
});


export default AdvertFilterApi;
