import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'service/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  const advertTypePK = detail.advertType?.pk || detail.advertType;
  return `/settings/adverttypes/${advertTypePK}/services/${detail[pkField]}/`
}


const ServiceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServiceList: getList(builder, baseApiUrl, 'ServiceList'),
    getServiceSimpleList: getList(builder, baseApiUrl, 'ServiceSimpleList', { simple: true }),

    getServiceDetail: getDetail(builder, baseApiUrl, 'ServiceDetail'),
    getServiceFormDetail: getDetail(builder, baseApiUrl, 'ServiceFormDetail', { forEdit: true }),

    updateService: update(builder, baseApiUrl, pkField),

    deleteService: remove(builder, baseApiUrl),
    batchDeleteService: batchRemove(builder, baseApiUrl),
  }),
});

export function useServiceSimpleList(filterParams, items) {
  return useLoadedItems(ServiceApi.endpoints.getServiceSimpleList, filterParams, items);
}

export function useServiceList(filterParams, items) {
  return useLoadedItems(ServiceApi.endpoints.getServiceList, filterParams, items);
}

export default ServiceApi;
