import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'featuretype/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/featuretypes/${detail[pkField]}/`
}


const FeatureTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureTypeList: getList(builder, baseApiUrl, 'FeatureTypeList'),
    getFeatureTypeSimpleList: getList(builder, baseApiUrl, 'FeatureTypeSimpleList', { simple: true }),

    getFeatureTypeDetail: getDetail(builder, baseApiUrl, 'FeatureTypeDetail'),
    getFeatureTypeFormDetail: getDetail(builder, baseApiUrl, 'FeatureTypeFormDetail', { forEdit: true }),

    updateFeatureType: update(builder, baseApiUrl, pkField),

    deleteFeatureType: remove(builder, baseApiUrl),
    batchDeleteFeatureType: batchRemove(builder, baseApiUrl),
  }),
});


export function useFeatureTypeList(filterParams, items) {
  return useLoadedItems(FeatureTypeApi.endpoints.getFeatureTypeList, filterParams, items);
}


export default FeatureTypeApi;
