import { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { ConfigProvider } from 'antd'

import { appInit } from 'store/features/appSlice'
import { AppLayout } from 'common/layout'
import LoginPage from 'pages/LoginPage'
import HomePage from 'pages/HomePage'
import Page404 from 'pages/Page404'
import CountryListPage from 'pages/CountryListPage'
import CountryDetailPage from 'pages/CountryDetailPage'
import CityDetailPage from 'pages/CityDetailPage'
import { SiteLayout } from 'components/Site'
import SiteListPage from 'pages/SiteListPage'
import SiteDetailPage from 'pages/SiteDetailPage'
import CategoryListPage from 'pages/CategoryListPage'
import StaffListPage from 'pages/StaffListPage'
import ClientListPage from 'pages/ClientListPage'
import AdvertTypeListPage from 'pages/AdvertTypeListPage'
import AdvertTypeDetailPage from 'pages/AdvertTypeDetailPage'
import ServiceDetailPage from 'pages/ServiceDetailPage'
import AdvertListPage from 'pages/AdvertListPage'
import AdvertDetailPage from 'pages/AdvertDetailPage'
import SeoTemplateListPage from 'pages/SeoTemplateListPage'
import PageListPage from 'pages/PageListPage'

import "antd/dist/reset.css"

const COLORS = {
  colorPrimary: 'var(--ant-primary-color)',
  colorPrimaryHover: 'var(--ant-primary-color-hover)',
  colorPrimaryActive: 'var(--ant-primary-color-active)',
};


const ProtectedRoute = ({ allowedRoles }) => {
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const location = useLocation();

  return token && (user?.is_superuser || user?.is_staff) ? (
    <Outlet />
  ) : token && user ? (
    <Navigate to='/' replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appInit());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: COLORS,
          Button: COLORS,
          Switch: {
            colorPrimary: 'var(--ant-success-color)',
            colorPrimaryHover: 'var(--ant-success-color-hover)',
            colorPrimaryActive: 'var(--ant-success-color-active)',
            handleSize: 24,
            innerMaxMargin: 30,
            trackHeight: 28,
            trackMinWidth: 64,
            trackPadding: 2,
          },
          Tabs: COLORS,
          Pagination: COLORS,
          colorLink: 'var(--ant-primary-color)',
        },
        token: {
          colorLink: 'var(--ant-primary-color)',
          colorLinkHover: 'var(--ant-primary-color-hover)',
          colorLinkActive: 'var(--ant-primary-color-active)',
        }
      }}
    >
      <Routes>
        <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<HomePage />} />

            <Route path="/sites/" element={<SiteListPage />} />
            <Route path="/sites/:sitePK/" element={<SiteLayout />}>
              <Route index element={<SiteDetailPage />} />

              <Route path="adverts/:advertTypePK/" element={<AdvertListPage />} />
              <Route path="adverts/:advertTypePK/:status/" element={<AdvertListPage />} />
              <Route path="adverts/:advertTypePK/detail/:advertPK/" element={<AdvertDetailPage />} />

              <Route path="categories/" element={<CategoryListPage />} />

              <Route path="seotemplates/" element={<SeoTemplateListPage />} />
              <Route path="pages/" element={<PageListPage />} />

              <Route path="settings/" element={<SiteDetailPage />} />
            </Route>

            <Route path="/clients/">
              <Route index element={<ClientListPage />} />
            </Route>

            <Route path="/settings/">
              <Route path='countries/' element={<CountryListPage />} />
              <Route path='countries/:countryPK/' element={<CountryDetailPage />} />
              <Route path='countries/:countryPK/:cityPK/' element={<CityDetailPage />} />

              <Route path='staffs/' element={<StaffListPage />} />

              <Route path='adverttypes/' element={<AdvertTypeListPage />} />
              <Route path='adverttypes/:advertTypePK/' element={<AdvertTypeDetailPage />} />
              <Route path='adverttypes/:advertTypePK/services/:servicePK/' element={<ServiceDetailPage />} />
            </Route>
          </Route>
        </Route>

        <Route path='/login/' element={<LoginPage />} />

        <Route path='*' element={<Page404 />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
