import { Row, Col } from 'antd'

import { FormFields } from 'common/components'
import { PageTypeField, ObjectTypeField } from 'components/Page'
import { SeoTemplateSelectField } from 'components/SeoTemplate'

const { TextField, TextareaField, SwitchField } = FormFields;


const MainFieldset = ({ form, formDetail, values }) => {
  const pageType = formDetail?.pageType || values?.pageType;
  const seoTemplateOff = pageType !== 'detail' || formDetail?.seoTemplateOff || values?.seoTemplateOff;

  const onPageTypeChange = (value) => {
    form.setFieldValue('objectType', value === 'page' ? 'page' : '');
  }

  return (
    <>
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <PageTypeField
            label="Тип" name="pageType" required={true}
            exclude={pageType !== 'detail' ? ['detail'] : []}
            disabled={pageType === 'detail'}
            onChange={onPageTypeChange}
          />
        </Col>
        <Col span={12}>
          <ObjectTypeField
            label="Тип объекта" name="objectType" required={true}
            exclude={pageType === 'listing' ? ['page'] : []}
            only={pageType === 'page' ? ['page'] : []}
            disabled={pageType === 'detail'}

          />
        </Col>
      </Row>

      <SwitchField label="Активно" name="isActive" />

      <SwitchField label="Не использовать seo шаблон" name="seoTemplateOff" />

      {!seoTemplateOff &&
        <SeoTemplateSelectField
          label="Seo шаблон" name="seoTemplate" required={true}
          filterParams={{ objectType: values?.objectType }}
        />}

      <TextField label="Путь" name="path" required={true} disabled={!seoTemplateOff} />

      <TextField label="Тайтл" name="title" required={true} disabled={!seoTemplateOff} />

      <TextField label="H1" name="h1" required={true} disabled={!seoTemplateOff} />

      <TextareaField label="Дескрипшн" name="description" required={true} disabled={!seoTemplateOff} />

      <TextareaField label="Текст" name="text" required={false} disabled={!seoTemplateOff} />
    </>
  );
};

export default MainFieldset;
