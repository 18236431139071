import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import ServiceApi, { pkField, getDetailUrl } from 'store/api/serviceApi'
import { ServiceActions } from 'store/features/serviceSlice'

import { ServiceValue } from 'components/Service'


const DefaultColumnList = ['name', 'code', 'sort', 'isActive', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const ServiceList = ({ title, columnList, baseFilters, filterParams, onFilterChange, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = ServiceApi.endpoints.getServiceList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ServiceApi.endpoints.updateService.useMutation();
  const [apiDelete, apiDeleteProps] = ServiceApi.endpoints.deleteService.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = ServiceApi.endpoints.batchDeleteService.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item) => dispatch(ServiceActions.openForm({ name: 'editForm', pk: item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const onAdd = () => dispatch(ServiceActions.openForm({ name: 'editForm' }));

  const fields = {
    name: {
      title: 'Название',
      sorter: false,
      render: (val, item) => (<ServiceValue detail={item} asLink={true} />),
    },
    code: { title: 'Код', sorter: false, width: 130 },
    sort: { title: 'Сортировка', sorter: false, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', name: 'Service', fieldName: 'isActive', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'view', getUrl: () => getDetailUrl(item) },
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "ServiceList" }]}

      baseFilters={baseFilters}
      filterQueryKey='service'
      filterFields={showFilters ? FilterFields : null}
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={true}
      hidePagination={false}

      onAdd={onAdd}
    />
  );
}

ServiceList.FilterFields = FilterFields;

export default ServiceList;
