import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'common/layout'
import { SmartFilter } from 'common/components'

import ClientApi, { pkField, getDetailUrl } from 'store/api/clientApi'
import { ClientActions } from 'store/features/clientSlice'
import { ClientList, ClientForm } from 'components/Client'
import { PasswordChangeForm } from 'components/Auth'


const ClientListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(ClientActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Клиенты"

      actions={[
        { icon: 'add', label: 'Добавить', type: 'primary', onClick: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="client" onChange={onFilterChange} fields={ClientList.FilterFields} />}
    >
      <ClientList showFilters={false} filterParams={params} onFilterChange={onFilterChange} />

      <ClientForm />

      <PasswordChangeForm
        getFormDetailEndpoint={ClientApi.endpoints.getClientFormDetail}
        updateEndpoint={ClientApi.endpoints.updateClient}
        reducerName="client"
        closeFormAction={ClientActions.closeForm()}
        invalidateTags={[{ type: "ClientList" }, { type: "ClientDetail" }]}
        pkField={pkField}
        getDetailUrl={getDetailUrl}
      />
    </Page >
  )
}

export default ClientListPage
