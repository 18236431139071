import { useEffect, useState } from 'react'
import { Form, Row, Col, Tree, InputNumber } from 'antd'

import { useServiceList } from 'store/api/serviceApi'

import './styles.css'


const getTree = (items) => {
  return items.map(item => {
    if (item.children?.length) {
      return { ...item, key: item.code, children: getTree(item.children), disableCheckbox: true }
    }
    return { ...item, key: item.code }
  })
}

const getTreeKeys = (items) => {
  return items.reduce((res, item) => {
    res.push(item.code);
    if (item.children?.length) {
      res = res.concat(getTreeKeys(item.children));
    }
    return res
  }, [])
}


const ServicesInput = ({ filterParams, items, value, onChange }) => {
  const [services] = useServiceList(filterParams, items);

  const [checkedServices, setCheckedServices] = useState([]);

  useEffect(() => {
    setCheckedServices({ ...value });
  }, [value]);

  const handleChange = (node, checked, price = null) => {
    const newValue = { ...checkedServices };
    if (!checked) {
      delete newValue[node.code];
    } else {
      newValue[node.code] = price
    }

    setCheckedServices(newValue);
    onChange && onChange(newValue)
  }

  const onCheck = (checkedKeys, { node, checked, event }) => {
    handleChange(node, checked);
  }

  if (!services?.length) return null;

  const expandedKeys = getTreeKeys(services);
  const checkedKeys = Object.keys(checkedServices);

  return (
    <Tree
      checkable
      selectable={false}
      autoExpandParent={true}
      defaultExpandAll={true}
      checkedKeys={checkedKeys}
      expandedKeys={expandedKeys}
      onCheck={onCheck}
      treeData={getTree(items)}
      titleRender={service => {
        if (service.children?.length) return service.name;
        return (
          <Row gutter={16} align="middle">
            <Col span={8}>
              <div style={{ lineHeight: '1.25' }}>{service.name}</div>
            </Col>
            <Col span={14}>
              <InputNumber
                placeholder="Цена"
                size="large"
                value={checkedServices[service.code]}
                onClick={e => e.stopPropagation()}
                onChange={value => handleChange(service, true, value)}
              />
            </Col>
          </Row>
        )
      }}
    />
  )
}


const ServicesField = ({ filterParams, items, name, label, required }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <ServicesInput filterParams={filterParams} items={items} />
    </Form.Item>
  )
}

export default ServicesField
