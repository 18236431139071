import { Row, Col } from 'antd'

import { FormFields } from 'common/components'
import { AdvertTypeSelectField } from 'components/AdvertType'
import { ServiceSelectField } from 'components/Service'

const { TextField, SlugField, NumberField, SwitchField } = FormFields;


const MainFieldset = ({ form, formDetail, initialValues, parent }) => {
  const onNameChange = (e) => {
    if (!formDetail) {
      const code = SlugField.slugify(e.target.value, 100);
      form.setFieldsValue({ code })
    }
  }

  let serviceFilterParams = { advertType: form.getFieldValue('advertType') };
  if (parent && parent !== formDetail?.pk) {
    serviceFilterParams['withParent'] = parent
  }

  return (
    <Row gutter={32}>
      <Col span={16}>
        <AdvertTypeSelectField
          label="Тип объявления"
          name="advertType"
          required={true}
          disabled={!!initialValues?.advertType}
        />

        <ServiceSelectField
          label="Группа услуг"
          name="parent"
          required={false}
          filterParams={serviceFilterParams}
          exclude={formDetail?.pk ? [formDetail?.pk] : null}
        />

        <TextField label="Название" name="name" required={true} maxLength={100} onChange={onNameChange} />

        <SlugField label="Код" name="code" required={true} maxLength={100} />
      </Col>

      <Col span={8}>
        <SwitchField label="Активно" name="isActive" />

        <NumberField label="Сортировка" name="sort" required={true} />
      </Col>
    </Row>
  )
}

export default MainFieldset
