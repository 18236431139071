import { DetailValue } from 'common/components'
import { getDetailUrl } from 'store/api/seoTemplateApi'
import { ObjectTypeField } from 'components/Page'


const SeoTemplateValue = ({ detail, asLink = true, isBack = true }) => {
  const objectType = ObjectTypeField.OBJECT_TYPE[detail.objectType] || '';
  return (
    <DetailValue className="flex" url={asLink && getDetailUrl(detail)} isBack={isBack}>
      Шаблон для «{objectType}»: {detail?.title}
    </DetailValue>
  );
};


export default SeoTemplateValue
