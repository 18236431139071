import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Page } from 'common/layout'
import { SmartFilter } from 'common/components'

import { AdvertTypeActions } from 'store/features/advertTypeSlice'
import { AdvertTypeList, AdvertTypeForm } from 'components/AdvertType'


const AdvertTypeListPage = () => {
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(AdvertTypeActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  return (
    <Page
      title="Типы объявлений"

      actions={[
        { icon: 'add', label: 'Добавить', type: 'primary', onClick: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="advertType" onChange={onFilterChange} fields={AdvertTypeList.FilterFields} />}
    >
      <AdvertTypeList showFilters={false} filterParams={params} onFilterChange={onFilterChange} />

      <AdvertTypeForm />
    </Page >
  )
}

export default AdvertTypeListPage
