import { useDispatch } from 'react-redux'

import { SmartTable, SmartFilter, OnOff, PropertyList, ActionButton } from 'common/components'
import AdvertApi, { pkField, getDetailUrl } from 'store/api/advertApi'
import { AdvertActions } from 'store/features/advertSlice'

import { AdvertValue, AdvertStatus } from 'components/Advert'
import { CityValue, CitySelectField } from 'components/City'
import { DistrictValue, DistrictSelectField } from 'components/District'
import { ClientValue } from 'components/Client'

const DefaultColumnList = ['name', 'info', 'status', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('isEnabled', 'Включено', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
  SmartFilter.getFieldConfig('city', 'Город', CitySelectField),
  SmartFilter.getFieldConfig('district', 'Район', DistrictSelectField),
];


const AdvertList = ({ title, columnList, baseFilters, filterParams, onFilterChange, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = AdvertApi.endpoints.getAdvertList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = AdvertApi.endpoints.updateAdvert.useMutation();
  const [apiDelete, apiDeleteProps] = AdvertApi.endpoints.deleteAdvert.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = AdvertApi.endpoints.batchDeleteAdvert.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item) => dispatch(AdvertActions.openForm({ name: 'editForm', pk: item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);
  const onRestore = (item) => apiUpdate({ [pkField]: item[pkField], restore: true });

  const fields = {
    name: {
      title: 'Название', sorter: true,
      render: (val, item) => (<AdvertValue detail={item} />),
    },
    info: {
      title: 'Информация', sorter: false, width: 224,
      render: (val, item) => (
        <PropertyList
          style={{ maxWidth: '200px' }}
          items={[
            { label: 'Город:', value: <CityValue detail={item.city} style={{ maxWidth: '100px' }} /> },
            { label: 'Район:', value: <DistrictValue detail={item.district} style={{ maxWidth: '100px' }} /> },
            { label: 'Клиент:', value: <ClientValue detail={item.user} asLink={false} showPhoto={false} showSecondary={false} /> },
            { label: 'Телефон:', value: item.contactPhone },
            { label: 'Whatsapp:', value: item.contactWhatsapp },
            { label: 'Telegram:', value: item.contactTelegram },
          ]}
        />
      )
    },
    status: {
      title: 'Статус', sorter: false, width: 136,
      render: (val, item) => (
        <>
          <AdvertStatus detail={item} showStatuses={true} />
        </>
      ),
    },
    isEnabled: {
      title: 'Активность', width: 94,
      render: (val, item) => (
        <>
          <OnOff detail={item} name='Advert' fieldName='isEnabled' {...{ pkField, apiUpdate, apiUpdateProps }} />
        </>
      ),
    },

    actions: {
      align: 'right',
      width: 192,
      render: (val, item) => (
        <>
          <ActionButton
            actions={[
              { icon: 'view', getUrl: () => getDetailUrl(item) },
              { icon: 'edit', onClick: () => onEdit(item) },
              item?.isDeleted && { icon: 'restore', label: 'Восстановить', onClick: () => onRestore(item) },
              !item?.isDeleted && { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
            ]}
          />
          {!item?.isDeleted && <PropertyList
            align="right"
            style={{ marginTop: '16px' }}
            items={[
              { label: 'Включено:', value: <OnOff size="small" detail={item} name='Advert' fieldName='isEnabled' {...{ pkField, apiUpdate, apiUpdateProps }} /> },
              //!item?.isDeleted && { label: 'Автоподнятие:', value: <OnOff size="small" detail={item} name='Advert' fieldName='raiseAuto' {...{ pkField, apiUpdate, apiUpdateProps }} /> },
              //!item?.isDeleted && { label: 'Автовыделение:', value: <OnOff size="small" detail={item} name='Advert' fieldName='markAuto' {...{ pkField, apiUpdate, apiUpdateProps }} /> },
              //!item?.isDeleted && { label: 'Авто vip:', value: <OnOff size="small" detail={item} name='Advert' fieldName='vipAuto' {...{ pkField, apiUpdate, apiUpdateProps }} /> },
            ]}
          />}
        </>
      ),
    },
  }

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "AdvertList" }]}

      baseFilters={baseFilters}
      filterQueryKey='advert'
      filterFields={showFilters ? FilterFields : null}
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={true}
      hidePagination={false}
    />
  )
}

AdvertList.FilterFields = FilterFields;

export default AdvertList;
