
import { ServicesField } from 'components/Service'


const ServicesFieldset = ({ services }) => {
  return (
    <>
      <ServicesField name="services" items={services} />
    </>
  )
}

export default ServicesFieldset
