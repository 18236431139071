import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import SeoTemplateApi, { pkField, getDetailUrl } from 'store/api/seoTemplateApi'
import { SeoTemplateActions } from 'store/features/seoTemplateSlice'

import { SeoTemplateValue } from 'components/SeoTemplate'


const DefaultColumnList = ['name', 'path', 'h1', 'isActive', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const SeoTemplateList = ({ title, columnList, baseFilters, filterParams, onFilterChange, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = SeoTemplateApi.endpoints.getSeoTemplateList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = SeoTemplateApi.endpoints.updateSeoTemplate.useMutation();
  const [apiDelete, apiDeleteProps] = SeoTemplateApi.endpoints.deleteSeoTemplate.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = SeoTemplateApi.endpoints.batchDeleteSeoTemplate.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item) => dispatch(SeoTemplateActions.openForm({ name: 'editForm', pk: item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const fields = {
    name: {
      title: 'Название',
      sorter: false,
      render: (val, item) => (<SeoTemplateValue detail={item} asLink={false} />),
    },
    path: { title: 'Путь', sorter: false, width: 130 },
    h1: { title: 'H1', sorter: false, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', name: 'SeoTemplate', fieldName: 'isActive', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'view', getUrl: () => getDetailUrl(item) },
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "SeoTemplateList" }]}

      baseFilters={baseFilters}
      filterQueryKey='seoTemplate'
      filterFields={showFilters ? FilterFields : null}
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={true}
      hidePagination={false}
    />
  );
}

SeoTemplateList.FilterFields = FilterFields;

export default SeoTemplateList;
