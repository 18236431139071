import { useEffect, useState } from 'react'
import { Form } from 'antd'

import { useCategoryList } from 'store/api/categoryApi'
import { TreeSelect } from 'common/components'


const CategoriesInput = ({ filterParams, items, value, onChange }) => {
  const [categories] = useCategoryList(filterParams, items);

  const [checkedCategories, setCheckedCategories] = useState([]);

  useEffect(() => {
    const values = value ? [ ...value ] : [];
    setCheckedCategories(values);
  }, [value]);

  const handleChange = (codes) => {
    setCheckedCategories(codes);
    onChange && onChange(codes)
  }

  if (!categories?.length) return null;

  return (
    <TreeSelect
      treeOptions={categories}
      fieldNames={{ label: 'name', value: 'code', key: 'code', children: 'children' }}
      multiple={true}
      value={checkedCategories}
      onChange={handleChange}
    />
  )
}


const CategoriesField = ({ filterParams, items, name, label, required }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <CategoriesInput filterParams={filterParams} items={items} />
    </Form.Item>
  )
}

export default CategoriesField
