import { Form } from 'antd'

import { Select } from 'common/components'
import { pkField, useSeoTemplateSimpleList } from 'store/api/seoTemplateApi'
import { ObjectTypeField } from 'components/Page'


export function SeoTemplateSelect({ filterParams, items, required, ...selectProps }) {
  const [countries] = useSeoTemplateSimpleList(filterParams, items);

  const options = countries.map(item => {
    const objectType = ObjectTypeField.OBJECT_TYPE[item.objectType] || '';
    return { ...item, name: `Шаблон для «${objectType}»: ${item?.title}` }
  });

  return (
    <Select
      options={options}
      fieldNames={{ label: 'name', value: pkField }}
      optionFilterProp="name"
      {...selectProps}
    />
  )
}


const SeoTemplateSelectField = ({ name, label, required, disabled, ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <SeoTemplateSelect required={required} disabled={disabled} {...selectProps} />
    </Form.Item>
  );
}

SeoTemplateSelectField.getValue = (values) => {
  return values;
}

SeoTemplateSelectField.getDisplayValue = (values, config) => {
  return values
};

export default SeoTemplateSelectField
