import { Avatar } from 'antd'
import { PictureOutlined } from '@ant-design/icons'

import { getThumbnailUrl } from 'common/utils/helpers'
import { DetailValue } from 'common/components'
import { getDetailUrl } from 'store/api/advertApi'

import './styles.css'


const AdvertValue = ({ detail, asLink = true, isBack = true, replace = false, style = null }) => {
  const photos = detail?.photos || [];

  return (
    <DetailValue className='advertValue' style={style} url={asLink && getDetailUrl(detail)} isBack={isBack} replace={replace}>
      <Avatar
        className='advertValue__photo' shape="square" size={64}
        icon={<PictureOutlined />}
        src={getThumbnailUrl(photos[0]?.pk, 64, 64)}
      />
      <div className='advertValue__name'>
        <span>{detail?.name || '-'}</span>

      </div>
    </DetailValue>
  )
}


export default AdvertValue
