import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from "react-router-dom"

import { Page } from 'common/layout'
import { SmartFilter } from 'common/components'

import AdvertTypeApi from 'store/api/advertTypeApi'
import AdvertApi from 'store/api/advertApi'
import { AdvertActions } from 'store/features/advertSlice'
import { AdvertList, AdvertForm, AdvertStatus } from 'components/Advert'


const STATUS_TABS = AdvertStatus.STATUS_LIST.map(([key, title]) => ({ getLabel: (count) => `${title} ${count}`, key }));


const AdvertListPage = () => {
  const { sitePK, advertTypePK, status } = useParams();
  const basePath = `/sites/${sitePK}/adverts/${advertTypePK}/`;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(AdvertActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  const [apiGetDetail, apiGetDetailProps] = AdvertTypeApi.endpoints.getAdvertTypeDetail.useLazyQuery();
  const advertType = apiGetDetailProps.data;

  useEffect(() => {
    if (advertTypePK) {
      apiGetDetail(advertTypePK);
    }
    // eslint-disable-next-line
  }, [advertTypePK]);

  const [apiGetStatuses, apiGetStatusesProps] = AdvertApi.endpoints.getAdvertStatuses.useLazyQuery();
  const statuses = apiGetStatusesProps.data || {};
  const tabs = STATUS_TABS.map(item => ({ label: item.getLabel(statuses[item.key] || ''), key: item.key }));

  const baseFilters = { site: sitePK, advertType: advertTypePK, };
  useEffect(() => {
    apiGetStatuses({ baseFilters });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!status) {
      const key = STATUS_TABS[0].key;
      navigate({ pathname: `${basePath}${key}/` }, { replace: true });
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <Page
      title={advertType?.name}

      actions={[
        { icon: 'add', label: 'Добавить', type: 'primary', onClick: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="advert" onChange={onFilterChange} fields={AdvertList.FilterFields} />}

      basePath={basePath}
      tabs={tabs}
    >
      {status && <AdvertList
        baseFilters={{ ...baseFilters, status }}
        showFilters={false} filterParams={params} onFilterChange={onFilterChange}
      />}
      <AdvertForm site={sitePK} advertType={advertTypePK} />
    </Page >
  )
}

export default AdvertListPage
