import { PricesField } from 'components/PriceType'


const PricesFieldset = ({ priceTypes }) => {
  return (
    <>
      <PricesField name="prices" items={priceTypes} />
    </>
  )
}

export default PricesFieldset
