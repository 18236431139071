import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'adverttype/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/settings/adverttypes/${detail[pkField]}/`
}


const AdvertTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAdvertTypeList: getList(builder, baseApiUrl, 'AdvertTypeList'),
    getAdvertTypeSimpleList: getList(builder, baseApiUrl, 'AdvertTypeSimpleList', { simple: true }),

    getAdvertTypeDetail: getDetail(builder, baseApiUrl, 'AdvertTypeDetail'),
    getAdvertTypeFormDetail: getDetail(builder, baseApiUrl, 'AdvertTypeFormDetail', { forEdit: true }),

    updateAdvertType: update(builder, baseApiUrl, pkField),

    deleteAdvertType: remove(builder, baseApiUrl),
    batchDeleteAdvertType: batchRemove(builder, baseApiUrl),
  }),
});

export function useAdvertTypeSimpleList(filterParams, items) {
  return useLoadedItems(AdvertTypeApi.endpoints.getAdvertTypeSimpleList, filterParams, items);
}

export default AdvertTypeApi;
