import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import AdvertTypeApi, { pkField, getDetailUrl } from 'store/api/advertTypeApi'
import { AdvertTypeActions } from 'store/features/advertTypeSlice'

import { AdvertTypeValue } from 'components/AdvertType'


const DefaultColumnList = ['name', 'code', 'sort', 'isActive', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const AdvertTypeList = ({ title, columnList, baseFilters, filterParams, onFilterChange, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = AdvertTypeApi.endpoints.getAdvertTypeList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = AdvertTypeApi.endpoints.updateAdvertType.useMutation();
  const [apiDelete, apiDeleteProps] = AdvertTypeApi.endpoints.deleteAdvertType.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = AdvertTypeApi.endpoints.batchDeleteAdvertType.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item) => dispatch(AdvertTypeActions.openForm({ name: 'editForm', pk: item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<AdvertTypeValue detail={item} />),
    },
    code: { title: 'Код', sorter: false, width: 130 },
    sort: { title: 'Сортировка', sorter: true, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', name: 'AdvertType', fieldName: 'isActive', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'view', getUrl: () => getDetailUrl(item) },
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "AdvertTypeList" }]}

      baseFilters={baseFilters}
      filterQueryKey='advertType'
      filterFields={showFilters ? FilterFields : null}
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={true}
      hidePagination={false}
    />
  );
}

AdvertTypeList.FilterFields = FilterFields;

export default AdvertTypeList;
