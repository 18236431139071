import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'common/layout'
import { OnOff } from 'common/components'

import AdvertTypeApi, { pkField } from 'store/api/advertTypeApi'
import { AdvertTypeActions } from 'store/features/advertTypeSlice'
import { AdvertTypeForm, AdvertTypeDetail } from 'components/AdvertType'
import { PriceTypeList, PriceTypeForm } from 'components/PriceType'
import { FeatureTypeList, FeatureTypeForm } from 'components/FeatureType'
import { ServiceList, ServiceForm } from 'components/Service'


const AdvertTypeDetailPage = () => {
  const { advertTypePK } = useParams();

  const detailPK = advertTypePK;

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = AdvertTypeApi.endpoints.getAdvertTypeDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = AdvertTypeApi.endpoints.updateAdvertType.useMutation();
  const [apiDelete, apiDeleteProps] = AdvertTypeApi.endpoints.deleteAdvertType.useMutation();

  const detail = apiGetDetailProps.data;

  const onEdit = () => {
    dispatch(AdvertTypeActions.openForm({ name: 'editForm', pk: detailPK }))
  };

  const activeSwitch = (
    <OnOff
      name="AdvertType"
      detail={detail}
      pkField={pkField}
      fieldName="isActive"
      apiUpdate={apiUpdate}
      apiUpdateProps={apiUpdateProps}
    />
  );

  const detailTitle = detail?.name ? `«${detail?.name}»` : '';

  return (
    <DetailPage
      title={`Тип объявления ${detailTitle}`}
      detailPK={detailPK}
      detail={detail}

      basePath='/sites/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      actions={[
        { label: 'Активно', component: activeSwitch },
        { icon: 'edit', label: 'Редактировать', type: 'primary', onClick: () => onEdit() },
      ]}
    >
      <AdvertTypeDetail detail={detail} />
      <AdvertTypeForm showGoToDetailButton={false} />

      <PriceTypeList title="Типы цен" baseFilters={{ advertType: advertTypePK }} />
      <PriceTypeForm showGoToDetailButton={false} initials={{ advertType: parseInt(advertTypePK) }} />

      <FeatureTypeList title="Типы свойств" baseFilters={{ advertType: advertTypePK }} />
      <FeatureTypeForm showGoToDetailButton={false} initials={{ advertType: parseInt(advertTypePK) }} />

      <ServiceList title="Услуги" baseFilters={{ advertType: advertTypePK }} />
      <ServiceForm showGoToDetailButton={true} initials={{ advertType: parseInt(advertTypePK) }} />
    </DetailPage>
  );
}

export default AdvertTypeDetailPage
