import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'common/layout'
import { OnOff } from 'common/components'

import SiteApi, { pkField } from 'store/api/siteApi'
import { SiteActions } from 'store/features/siteSlice'
import { SiteDetail, SiteForm } from 'components/Site'


const SiteDetailPage = () => {
  const { sitePK } = useParams();
  const detailPK = sitePK;

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = SiteApi.endpoints.getSiteDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = SiteApi.endpoints.updateSite.useMutation();
  const [apiDelete, apiDeleteProps] = SiteApi.endpoints.deleteSite.useMutation();

  const detail = apiGetDetailProps.data;

  const onEdit = () => {
    dispatch(SiteActions.openForm({ name: 'editForm', pk: detailPK }))
  };

  const activeSwitch = (
    <OnOff
      name="Site"
      detail={detail}
      pkField={pkField}
      fieldName="nginxIsActive"
      apiUpdate={apiUpdate}
      apiUpdateProps={apiUpdateProps}
    />
  );

  const detailTitle = detail?.domain ? `«${detail?.domain}»` : '';

  return (
    <DetailPage
      title={`Сайт ${detailTitle}`}
      detailPK={detailPK}
      detail={detail}

      showBack={false}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      actions={[
        { label: 'Активно', component: activeSwitch },
        { icon: 'edit', label: 'Редактировать', type: 'primary', onClick: () => onEdit() },
      ]}
    >
      <SiteDetail detail={detail} />

      <SiteForm showGoToDetailButton={false} />
    </DetailPage>
  );
}

export default SiteDetailPage
