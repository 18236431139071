import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { Page } from 'common/layout'
import { SmartFilter } from 'common/components'

import { PageActions } from 'store/features/pageSlice'
import { PageList, PageForm } from 'components/Page'


const PageListPage = () => {
  const { sitePK } = useParams();
  const dispatch = useDispatch();

  const [params, setParams] = useState(null);

  const onEdit = () => {
    dispatch(PageActions.openForm({ name: 'editForm' }))
  };

  const onFilterChange = (params) => {
    setParams(params)
  }

  const baseFilters = { site: sitePK };

  return (
    <Page
      title="Страницы"

      actions={[
        { icon: 'add', label: 'Добавить', type: 'primary', onClick: () => onEdit() },
      ]}

      headerMiddle={<SmartFilter queryKey="page" onChange={onFilterChange} fields={PageList.FilterFields} />}
    >
      <PageList
        baseFilters={baseFilters}
        showFilters={false} filterParams={params} onFilterChange={onFilterChange}
      />

      <PageForm site={sitePK} />
    </Page >
  )
}

export default PageListPage
