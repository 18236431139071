import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"

import { DetailPage } from 'common/layout'
import { OnOff } from 'common/components'

import CountryApi, { pkField } from 'store/api/countryApi'
import { CountryActions } from 'store/features/countrySlice'
import { CountryForm, CountryDetail } from 'components/Country'
import { CityList, CityForm } from 'components/City'


const CountryDetailPage = () => {
  const { countryPK } = useParams();

  const detailPK = countryPK;

  const dispatch = useDispatch();

  const [apiGetDetail, apiGetDetailProps] = CountryApi.endpoints.getCountryDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CountryApi.endpoints.updateCountry.useMutation();
  const [apiDelete, apiDeleteProps] = CountryApi.endpoints.deleteCountry.useMutation();

  const detail = apiGetDetailProps.data;

  const onEdit = () => {
    dispatch(CountryActions.openForm({ name: 'editForm', pk: detailPK }))
  };

  const activeSwitch = (
    <OnOff
      name="Country"
      detail={detail}
      pkField={pkField}
      fieldName="isActive"
      apiUpdate={apiUpdate}
      apiUpdateProps={apiUpdateProps}
    />
  );

  const detailTitle = detail?.name ? `«${detail?.name}»` : '';

  return (
    <DetailPage
      title={`Страна ${detailTitle}`}
      detailPK={detailPK}
      detail={detail}

      basePath='/sites/'
      showBack={true}

      apiGetDetail={apiGetDetail}
      apiGetDetailProps={apiGetDetailProps}
      apiDelete={apiDelete}
      apiDeleteProps={apiDeleteProps}

      actions={[
        { label: 'Активно', component: activeSwitch },
        { icon: 'edit', label: 'Редактировать', type: 'primary', onClick: () => onEdit() },
      ]}
    >
      <CountryDetail detail={detail} />

      {detail?.code && <CityList title="Города" baseFilters={{ country: detail?.code }} />}

      <CountryForm showGoToDetailButton={false} />
      <CityForm showGoToDetailButton={true} initials={{ country: parseInt(countryPK) }} />
    </DetailPage>
  );
}

export default CountryDetailPage
