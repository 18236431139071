import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import CityApi, { pkField, getDetailUrl } from 'store/api/cityApi'
import { CityActions } from 'store/features/citySlice'

import { CityValue } from 'components/City'


const DefaultColumnList = ['name', 'code', 'sort', 'isActive', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const CityList = ({ title, columnList, baseFilters, filterParams }) => {
  const [apiGetList, apiGetListProps] = CityApi.endpoints.getCityList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CityApi.endpoints.updateCity.useMutation();
  const [apiDelete, apiDeleteProps] = CityApi.endpoints.deleteCity.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = CityApi.endpoints.batchDeleteCity.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item = null) => dispatch(CityActions.openForm({ name: 'editForm', pk: item && item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<CityValue detail={item} />),
    },
    code: { title: 'Код', sorter: false, width: 130 },
    sort: { title: 'Сортировка', sorter: true, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', name: 'City', fieldName: 'isActive', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'view', getUrl: () => getDetailUrl(item) },
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "CityList" }]}

      baseFilters={baseFilters}
      filterQueryKey='city'
      filterFields={FilterFields}
      filterParams={filterParams}

      rowSelection={true}
      hidePagination={false}

      onAdd={onEdit}
    />
  );
}

export default CityList;
