import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from 'store/api/pageApi'


const initialState = {
  ...baseModelState,
}


export const page = createSlice({
  name: 'page',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updatePage);
  },
});

export default page.reducer;

export const PageActions = page.actions;
