import { Layout } from 'antd'

import { SiteNavigation, SiteDropdown } from 'components/Site'

import './styles.css'


const SiteSider = ({ detailPK }) => {
  return (
    <Layout.Sider
      className='siteSider'
      collapsed={false}
      breakpoint='lg'
      width='220'
      collapsedWidth='80'
      collapsible={false}
    >
      <SiteDropdown detailPK={detailPK} />

      <SiteNavigation detailPK={detailPK} />
    </Layout.Sider>
  )
}

export default SiteSider;
