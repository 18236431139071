import { FeaturesField } from 'components/FeatureType'


const FeaturesFieldset = ({ features }) => {
  return (
    <>
      <FeaturesField name="features" items={features} />
    </>
  )
}

export default FeaturesFieldset
