import { Dropdown, Spin } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'

import { pkField, useSiteSimpleList } from 'store/api/siteApi'
import { SiteValue } from 'components/Site'

import './styles.css'


const SiteDropdown = ({ detailPK, filterParams, items }) => {
  const [sites, isLoading] = useSiteSimpleList(filterParams, items);

  const currentSite = sites.filter(item => item[pkField] === parseInt(detailPK))[0];

  const menuItems = sites.map(item => {
    return {
      key: item[pkField],
      label: <SiteValue detail={item} asLink={true} replace={true} />
    }
  })

  return (
    <Dropdown loading menu={{ items: menuItems }} trigger='click' arrow={false} placement='bottom'>
      <div className='siteDropdown'>
        <Spin spinning={isLoading} />
        {currentSite &&
          <div className='siteDropdownCurrent'>
            <SiteValue detail={currentSite} asLink={false} />
            <CaretDownOutlined className='siteDropdownCurrent__caret' />
          </div>}
      </div>
    </Dropdown>
  )
}

export default SiteDropdown
