import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { api } from './api/api'
import app from './features/appSlice'
import auth from './features/authSlice'
import staff from './features/staffSlice'
import client from './features/clientSlice'
import country from './features/countrySlice'
import city from './features/citySlice'
import district from './features/districtSlice'
import site from './features/siteSlice'
import category from './features/categorySlice'
import service from './features/serviceSlice'
import files from './features/filesSlice'
import advert from './features/advertSlice'
import advertType from './features/advertTypeSlice'
import featureType from './features/featureTypeSlice'
import priceType from './features/priceTypeSlice'
import advertFilter from './features/advertFilterSlice'
import advertFilterField from './features/advertFilterFieldSlice'
import seoTemplate from './features/seoTemplateSlice'
import page from './features/pageSlice'


const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app,
  auth,
  staff,
  client,
  country,
  city,
  district,
  site,
  category,
  service,
  files,
  advert,
  advertType,
  featureType,
  priceType,
  advertFilter,
  advertFilterField,
  seoTemplate,
  page,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  /*middleware: [
    thunk,
    api.middleware, 
  ],*/

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
})

export const persistor = persistStore(store)
