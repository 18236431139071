import { TreeSelect as AntdTreeSelect } from 'antd'


const filterTreeNode = (input, treeNode) => {
  const searchValue = input.toLowerCase();
  let match = treeNode.name.toLowerCase().includes(searchValue);
  if (match) return true;

  if (treeNode.children) {
    let index = 0;
    while (index < treeNode.children.length) {
      match = filterTreeNode(input, treeNode.children[index]);
      if (match) return true;
      index++;
    }
  }

  return match;
}


const excludeByPk = (tree, exclude, pkField) => {
  return tree.reduce((res, item) => {
    const match = exclude.includes(item[pkField]);
    if (match) return res;

    const children = item.children ? excludeByPk(item.children, exclude, pkField) : null;
    res.push({ ...item, children });
    return res;
  }, [])
}


const TreeSelect = ({ treeOptions, fieldNames, pkField, exclude=[], placeholder = "Выберите", showSearch = true, allowClear = true, ...otherProps }) => {
  const filteredTreeOptions = exclude?.length ? excludeByPk(treeOptions, exclude, pkField) : treeOptions;

  return (
    <AntdTreeSelect
      placeholder={placeholder}
      showSearch={showSearch}
      allowClear={allowClear}
      size="large"
      treeData={filteredTreeOptions}
      fieldNames={fieldNames}
      filterTreeNode={filterTreeNode}
      {...otherProps}
    />
  )
}

export default TreeSelect
