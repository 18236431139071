import { useSelector } from 'react-redux'
import { Form } from 'antd'

import { FileUpload } from 'common/components'
import { filesUploadUrl } from 'store/api/filesApi'

import "./styles.css"


const FileUploadField = ({ name, label, required, multiple, data, ...uploadProps }) => {
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const uploadData = { ...data, user: user.pk }

  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]} valuePropName="fileList">
      <FileUpload
        action={filesUploadUrl}
        headers={{ 'Authorization': `JWT ${token}` }}
        data={uploadData}
        multiple={multiple}
        {...uploadProps}
      />
    </Form.Item>
  );
};

export default FileUploadField;
