import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Layout, Avatar } from 'antd'
import {
  AppstoreOutlined, TeamOutlined, SettingOutlined, UserOutlined, LogoutOutlined
} from '@ant-design/icons'

import { useLogoutUserMutation } from 'store/api/authApi'
import { Logo } from 'common/components'
import AppNavigation from '../AppNavigation'

import "./styles.css"


const getMainMenuItems = (user) => {
  let items = [
    //AppNavigation.getItem('Рабочий стол', '/', <DashboardOutlined />),
  ];

  // const isAdmin = user?.is_staff;
  // const roles = user?.roles || [];

  items = items.concat([
    AppNavigation.getItem('Сайты', '/sites/', <AppstoreOutlined />),
    AppNavigation.getItem('Клиенты', '/clients/', <TeamOutlined />),
    //AppNavigation.getItem('Биллинг', '/billing/', <DollarOutlined />),
    //AppNavigation.getItem('Сообщения', '/messages/', <MessageOutlined />),
  ]);

  return items;
}


const getExtraMenuItems = (user, logoutUser) => {
  let items = [];

  items = items.concat([
    AppNavigation.getItem(user.email, 'user', <Avatar size={30} icon={<UserOutlined />} src={user.photo} style={{ backgroundColor: '#fff' }} />, null, [
      AppNavigation.getItem('Профиль', '/profile/'),

      AppNavigation.getItem('Выход', '/logout/', <LogoutOutlined />, null, null, { 'onClick': () => logoutUser() }),
    ]),

    AppNavigation.getItem('Настройки', '/settings/', <SettingOutlined />, null, [
      AppNavigation.getItem('Страны и города', '/settings/countries/'),
      AppNavigation.getItem('Типы объявлений', '/settings/adverttypes/'),
      AppNavigation.getItem('Администраторы', '/settings/staffs/'),
    ]),
  ]);

  return items;
}


const AppLayout = () => {
  const user = useSelector(state => state.auth.user);

  const [logoutUser] = useLogoutUserMutation();

  return (
    <Layout className="app">
      <Layout.Sider
        className="appSider"
        collapsed={true}
        collapsedWidth="80"
      >
        <Logo src="/img/advertycms_logo_icon.png" />

        <AppNavigation items={getMainMenuItems(user)} />

        <AppNavigation items={getExtraMenuItems(user, logoutUser)} fixed={true} />
      </Layout.Sider>

      <Outlet />
    </Layout>
  );
};

export default AppLayout;
