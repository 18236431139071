import { FormFields } from 'common/components'

const { ImageUploadField, FileUploadField } = FormFields;


const MediaFieldset = () => {
  return (
    <>
      <ImageUploadField
        label="Фотографии" name="photos" size="large" data={{ category: 'advert' }} required={false}
        uploadText="Загрузить" multiple={true}
      />

      <FileUploadField
        label="Видео" name="videos" data={{ category: 'advert' }} required={false}
        uploadText="Загрузить" multiple={false} accept=".mp4"
      />
    </>
  )
}

export default MediaFieldset
