import classNames from 'classnames'

import './styles.css'


export default function Logo({ className, src }) {
  const cx = classNames(['logo', className]);

  return (
    <div className={cx}>
      <img className="logoImg" src={src} alt="logo" />
    </div>
  );
}
