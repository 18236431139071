import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import ServiceApi, { pkField, getDetailUrl } from 'store/api/serviceApi'
import { ServiceActions } from 'store/features/serviceSlice'
import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey, parent }) => {
  const { form, formDetail, values } = useContext(Form.FormContext);

  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset parent={parent} form={form} formDetail={formDetail} values={values} />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const ServiceForm = ({ parent, initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.service);

  const [apiGetFormDetail, apiGetFormDetailProps] = ServiceApi.endpoints.getServiceFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = ServiceApi.endpoints.updateService.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    if (values.parent === undefined) values.parent = null;
    apiUpdate(values);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="Service"
      titleNew="Новая услуга"
      titleEdit={`${formDetail?.name}`}

      open={activeForm === 'editForm'}
      closeFormAction={ServiceActions.closeForm()}
      invalidateTags={[{ type: "ServiceList" }, { type: "ServiceDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} parent={parent} />
    </DrawerModelForm>
  );
}


  export default ServiceForm
