const ENV = {
  local: {
    apiUrl: 'http://localhost:8000',
    wsUrl: 'ws://localhost:8000/ws/',
    countryCode: 'ru',
  },
  production: {
    apiUrl: 'https://api.rabota-devushkam.top',
    wsUrl: 'wss://api.rabota-devushkam.top/ws/',
    countryCode: 'ru',
  },
};

const urls = ENV['production'];

export const API_URL = urls.apiUrl;
export const WS_URL = urls.wsUrl;
export const COUNTRY_CODE = urls.countryCode;
