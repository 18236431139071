import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from 'store/api/priceTypeApi'


const initialState = {
  ...baseModelState,
}


export const priceType = createSlice({
  name: 'priceType',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updatePriceType);
  },
});

export default priceType.reducer;

export const PriceTypeActions = priceType.actions;
