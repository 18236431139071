import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { useLoadedItems } from 'common/utils/useHooks'


const baseApiUrl = 'pricetype/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  return `/pricetypes/${detail[pkField]}/`
}


const PriceTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPriceTypeList: getList(builder, baseApiUrl, 'PriceTypeList'),
    getPriceTypeSimpleList: getList(builder, baseApiUrl, 'PriceTypeSimpleList', { simple: true }),

    getPriceTypeDetail: getDetail(builder, baseApiUrl, 'PriceTypeDetail'),
    getPriceTypeFormDetail: getDetail(builder, baseApiUrl, 'PriceTypeFormDetail', { forEdit: true }),

    updatePriceType: update(builder, baseApiUrl, pkField),

    deletePriceType: remove(builder, baseApiUrl),
    batchDeletePriceType: batchRemove(builder, baseApiUrl),
  }),
});

export function usePriceTypeList(filterParams, items) {
  return useLoadedItems(PriceTypeApi.endpoints.getPriceTypeList, filterParams, items);
}

export default PriceTypeApi;
