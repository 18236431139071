import { useDispatch } from 'react-redux'

import { dictToArray } from 'common/utils/helpers'
import { SmartTable, TableColumns, SmartFilter } from 'common/components'
import FeatureTypeApi, { pkField, getDetailUrl } from 'store/api/featureTypeApi'
import { FeatureTypeActions } from 'store/features/featureTypeSlice'

import { FeatureTypeValue } from 'components/FeatureType'


const DefaultColumnList = ['name', 'code', 'type', 'variants', 'sort', 'isActive', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const FeatureTypeList = ({ title, columnList, baseFilters, filterParams }) => {
  const [apiGetList, apiGetListProps] = FeatureTypeApi.endpoints.getFeatureTypeList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = FeatureTypeApi.endpoints.updateFeatureType.useMutation();
  const [apiDelete, apiDeleteProps] = FeatureTypeApi.endpoints.deleteFeatureType.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = FeatureTypeApi.endpoints.batchDeleteFeatureType.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item = null) => dispatch(FeatureTypeActions.openForm({ name: 'editForm', pk: item && item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<FeatureTypeValue detail={item} asLink={false} />),
    },
    code: { title: 'Код', sorter: false, width: 130 },
    type: { title: 'Тип', sorter: false, width: 80 },
    variants: { 
      title: 'Варианты', 
      sorter: false, 
      width: 200,
      render: (val, item) => (
        dictToArray(val || {}, 'code', null, true).sort().join(', ')
      ),
    },
    sort: { title: 'Сортировка', sorter: true, width: 130 },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', name: 'FeatureType', fieldName: 'isActive', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'view', getUrl: () => getDetailUrl(item) },
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "FeatureTypeList" }]}

      baseFilters={baseFilters}
      filterQueryKey='featureType'
      filterFields={FilterFields}
      filterParams={filterParams}

      rowSelection={true}
      hidePagination={false}

      onAdd={onEdit}
    />
  );
}

export default FeatureTypeList;
