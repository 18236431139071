import { useEffect } from 'react'

import { Row, Col } from 'antd'

import { COUNTRY_CODE } from 'config'
import { FormFields } from 'common/components'
import { ClientSelectField } from 'components/Client'
import { CitySelectField } from 'components/City'
import { DistrictSelectField } from 'components/District'
import { CategoriesField } from 'components/Category'

const { TextField, HtmlField, SwitchField } = FormFields;

const MainFieldset = ({ form, formDetail, values }) => {
  useEffect(() => {
    if (values?.city !== formDetail?.city) {
      form.resetFields(['district']);
    }
    // eslint-disable-next-line
  }, [formDetail?.city, values?.city])

  return (
    <>
      <ClientSelectField label="Клиент" name="user" required={false} />

      <Row gutter={32} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <CitySelectField
            label="Город" name="city" required={true}
            filterParams={{ country: COUNTRY_CODE }}
          />
        </Col>
        <Col span={12}>
          <DistrictSelectField
            label="Район" name="district" required={false}
            filterParams={{ city: values?.city }}
            noLoad={!values?.city}
            disabled={!values?.city}
          />
        </Col>
      </Row>

      <CategoriesField label="Категории" name="categories" required={true} />

      <TextField label="Название" name="name" required={true} />

      <HtmlField label="Описание" name="text" required={true} />

      <SwitchField label="Включено" name="isEnabled" />
    </>
  )
}

export default MainFieldset
