import { Form } from 'antd'

import { Select } from 'common/components'
import { pkField, useAdvertTypeSimpleList } from 'store/api/advertTypeApi'


export function AdvertTypeSelect({ filterParams, items, required, ...otherProps }) {
  const [districts] = useAdvertTypeSimpleList(filterParams, items);

  return (
    <Select
      options={districts}
      fieldNames={{ label: 'name', value: pkField }}
      optionFilterProp="name"
      {...otherProps}
    />
  )
}


const AdvertTypeSelectField = ({ name, label, required, disabled, ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <AdvertTypeSelect required={required} disabled={disabled} {...selectProps} />
    </Form.Item>
  );
}

AdvertTypeSelectField.getValue = (values) => {
  return values;
}

AdvertTypeSelectField.getDisplayValue = (values, config) => {
  return values
};

export default AdvertTypeSelectField
