import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import CountryApi, { pkField, getDetailUrl } from 'store/api/countryApi'
import { CountryActions } from 'store/features/countrySlice'
import MainFieldset from './MainFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset />
    },
  ];

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const CountryForm = ({ initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.country);

  const [apiGetFormDetail, apiGetFormDetailProps] = CountryApi.endpoints.getCountryFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = CountryApi.endpoints.updateCountry.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    apiUpdate(values);
  };

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  };

  const prepareFormValues = (detail) => {
    return {
      ...detail,
    }
  };

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  };

  return (
    <DrawerModelForm
      name="Country"
      titleNew="Новая страна"
      titleEdit={`${formDetail?.name}`}

      open={activeForm === 'editForm'}
      closeFormAction={CountryActions.closeForm()}
      invalidateTags={[{ type: "CountryList" }, { type: "CountryDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  )
}

export default CountryForm
