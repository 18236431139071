import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { DrawerModelForm, Form } from 'common/components'
import SiteApi, { pkField, getDetailUrl } from 'store/api/siteApi'
import { SiteActions } from 'store/features/siteSlice'

import MainFieldset from './MainFieldset'
import GeoFieldset from './GeoFieldset'
import IconsFieldset from './IconsFieldset'
import MetaFieldset from './MetaFieldset'
import ContactsFieldset from './ContactsFieldset'
import WatermarkFieldset from './WatermarkFieldset'


const Fieldsets = ({ defaultActiveKey }) => {
  const { formDetail } = useContext(Form.FormContext);

  const fieldsets = [
    {
      key: 'main',
      label: 'Основное',
      children: <MainFieldset formDetail={formDetail} />,
      requiredFields: ['domain'],
    },
    {
      key: 'geo',
      label: 'Гео',
      children: <GeoFieldset />
    },
    {
      key: 'icons',
      label: 'Иконки',
      children: <IconsFieldset />
    },
    {
      key: 'meta',
      label: 'Мета-теги и скрипты',
      children: <MetaFieldset />
    },
    {
      key: 'contacts',
      label: 'Контакты',
      children: <ContactsFieldset />
    },
    {
      key: 'watermark',
      label: 'Водяной знак',
      children: <WatermarkFieldset />
    },
  ]

  return <Form.Fieldsets defaultActiveKey={defaultActiveKey} fieldsets={fieldsets} />
}


const SiteForm = ({ initials, showGoToDetailButton = true }) => {
  const { activeForm, formDetailPK } = useSelector(state => state.site);

  const [apiGetFormDetail, apiGetFormDetailProps] = SiteApi.endpoints.getSiteFormDetail.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = SiteApi.endpoints.updateSite.useMutation();

  const formDetail = apiGetFormDetailProps.data;

  const onFinish = (values) => {
    values.hiddenDomain = values.hiddenDomain ? `${values.hiddenDomain}.${values.domain}` : values.domain;
    apiUpdate(values);
  }

  const initialValues = {
    sort: 100,
    isActive: true,
    ...initials
  }

  const prepareFormValues = (detail) => {
    return {
      ...detail,
      hiddenDomain: detail.hiddenDomain.replace(`.${detail.domain}`, '').replace(detail.domain, '')
    }
  }

  const formProps = {
    showGoToDetailButton, pkField, getDetailUrl,
    initialValues, prepareFormValues,
    formDetailPK, apiGetFormDetail, apiGetFormDetailProps, apiUpdateProps, onFinish
  }

  return (
    <DrawerModelForm
      name="Site"
      titleNew="Новый сайт"
      titleEdit={`${formDetail?.domain}`}

      open={activeForm === 'editForm'}
      closeFormAction={SiteActions.closeForm()}
      invalidateTags={[{ type: "SiteList" }, { type: "SiteDetail" }]}

      {...formProps}
    >
      <Fieldsets defaultActiveKey={['main']} />
    </DrawerModelForm>
  )
}


export default SiteForm
