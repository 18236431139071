import { api, getList, getDetail, update, remove, batchRemove } from 'store/api/api'
import { combineDataQuery } from 'common/utils/urls'


const baseApiUrl = 'advert/';

export const pkField = 'pk';

export const getDetailUrl = (detail) => {
  if (!detail) return '';
  const sitePK = detail.site?.pk || detail.site;
  const advertTypePK = detail.advertType?.pk || detail.advertType;
  return `/sites/${sitePK}/adverts/${advertTypePK}/detail/${detail[pkField]}/`
}


const AdvertApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAdvertList: getList(builder, baseApiUrl, 'AdvertList'),
    getAdvertSimpleList: getList(builder, baseApiUrl, 'AdvertSimpleList', { simple: true }),

    getAdvertDetail: getDetail(builder, baseApiUrl, 'AdvertDetail'),
    getAdvertFormDetail: getDetail(builder, baseApiUrl, 'AdvertFormDetail', { forEdit: true }),

    updateAdvert: update(builder, baseApiUrl, pkField),

    deleteAdvert: remove(builder, baseApiUrl),
    batchDeleteAdvert: batchRemove(builder, baseApiUrl),

    getAdvertStatuses: builder.query({
      providesTags: [{ type: 'AdvertList', id: 'LIST' }],
      query: ({ params = null, baseFilters = null } = {}) => {
        return {
          url: 'advert/statuses/',
          params: combineDataQuery(params, baseFilters),
        }
      },
    }),
  }),
});


export default AdvertApi;
