import { Form } from 'antd'

import { Select } from 'common/components'
import { pkField, useSiteSimpleList } from 'store/api/siteApi'


export function SiteSelect({ filterParams, items, required, ...selectProps }) {
  const [sites] = useSiteSimpleList(filterParams, items);

  return (
    <Select
      options={sites}
      fieldNames={{ label: 'domain', value: pkField }}
      optionFilterProp="domain"
      {...selectProps}
    />
  )
}


const SiteSelectField = ({ name, label, required, disabled, ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <SiteSelect required={required} disabled={disabled} {...selectProps} />
    </Form.Item>
  )
}

SiteSelectField.getValue = (values) => {
  return values;
}

SiteSelectField.getDisplayValue = (values, config) => {
  return values
}

export default SiteSelectField
