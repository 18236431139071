import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from 'store/api/categoryApi'


const initialState = {
  ...baseModelState,
}


export const category = createSlice({
  name: 'category',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateCategory);
  },
});

export default category.reducer;

export const CategoryActions = category.actions;
