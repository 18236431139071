import { createSlice } from '@reduxjs/toolkit'

import { baseModelState, updateExtraReducers, openForm, closeForm } from 'store/common'
import api from 'store/api/advertTypeApi'


const initialState = {
  ...baseModelState,
}


export const advertType = createSlice({
  name: 'advertType',
  initialState,
  reducers: {
    openForm,
    closeForm,
  },
  extraReducers: (builder) => {
    updateExtraReducers(builder, api.endpoints.updateAdvertType);
  },
});

export default advertType.reducer;

export const AdvertTypeActions = advertType.actions;
