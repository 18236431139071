import { Form } from 'antd'

import { TreeSelect } from 'common/components'
import { pkField, useCategorySimpleList } from 'store/api/categoryApi'


export function CategorySelect({ filterParams, items, required, exclude = [], ...selectProps }) {
  const [categories] = useCategorySimpleList(filterParams, items);

  return (
    <TreeSelect
      treeOptions={categories}
      fieldNames={{ label: 'name', value: pkField, children: 'children' }}
      pkField={pkField}
      exclude={exclude}
      {...selectProps}
    />
  )
}


const CategorySelectField = ({ name, label, required, disabled, filterParams, exclude = [], ...selectProps }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <CategorySelect required={required} disabled={disabled} exclude={exclude} filterParams={filterParams} {...selectProps} />
    </Form.Item>
  );
}

CategorySelectField.getValue = (values) => {
  return values;
}

CategorySelectField.getDisplayValue = (values, config) => {
  return values
};

export default CategorySelectField
