import { useDispatch } from 'react-redux'

import { SmartTable, TableColumns, SmartFilter, BoolValue, PropertyList } from 'common/components'
import PageApi, { pkField, getDetailUrl } from 'store/api/pageApi'
import { PageActions } from 'store/features/pageSlice'

import { SeoTemplateValue } from 'components/SeoTemplate'
import { PageValue } from 'components/Page'


const DefaultColumnList = ['name', 'title', 'seoTemplate', 'actions'];

const FilterFields = [
  SmartFilter.getFieldConfig('isActive', 'Активно', SmartFilter.FilterBool),
];


const PageList = ({ title, columnList, baseFilters, filterParams, onFilterChange, showFilters = true }) => {
  const [apiGetList, apiGetListProps] = PageApi.endpoints.getPageList.useLazyQuery();
  const [apiUpdate, apiUpdateProps] = PageApi.endpoints.updatePage.useMutation();
  const [apiDelete, apiDeleteProps] = PageApi.endpoints.deletePage.useMutation();
  const [apiBatchDelete, apiBatchDeleteProps] = PageApi.endpoints.batchDeletePage.useMutation();

  const dispatch = useDispatch();

  const onEdit = (item) => dispatch(PageActions.openForm({ name: 'editForm', pk: item[pkField] }));
  const onDelete = (item) => apiDelete(item[pkField]);

  const fields = {
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<PageValue detail={item} asLink={false} />),
    },
    seoTemplate: {
      title: 'Seo-шаблон', sorter: false, width: 224,
      render: (val, item) => {
        return (
          <PropertyList
            items={[
              { label: 'Тайтл', value: item.title, block: true },
              { label: 'Seo-шаблон:', value: val && !item.seoTemplateOff && <SeoTemplateValue detail={val} asLink={false} />, block: true },
              { label: 'Не использ. seo-шаблон:', value: item.seoTemplateOff && <BoolValue value={item.seoTemplateOff} /> },
              { label: 'Активно', value: <BoolValue value={item.isActive} /> },
            ]}
          />
        )
      },
    },
    isActive: TableColumns.getColumnOnOff({ title: 'Активно', name: 'Page', fieldName: 'isActive', pkField, apiUpdate, apiUpdateProps }),

    actions: TableColumns.getColumnActions({
      getActions: (item) => [
        { icon: 'view', getUrl: () => getDetailUrl(item) },
        { icon: 'edit', onClick: () => onEdit(item) },
        { icon: 'delete', confirm: 'Удалить', onClick: () => onDelete(item) },
      ]
    }),
  };

  return (
    <SmartTable
      title={title}
      pkField={pkField}

      fields={fields}
      columnList={columnList || DefaultColumnList}

      apiGetList={apiGetList}
      apiGetListProps={apiGetListProps}
      apiUpdateProps={apiUpdateProps}
      apiDeleteProps={apiDeleteProps}
      apiBatchDelete={apiBatchDelete}
      apiBatchDeleteProps={apiBatchDeleteProps}
      invalidateTags={[{ type: "PageList" }]}

      baseFilters={baseFilters}
      filterQueryKey='page'
      filterFields={showFilters ? FilterFields : null}
      filterParams={filterParams}
      onFilterChange={onFilterChange}

      rowSelection={true}
      hidePagination={false}
    />
  );
}

PageList.FilterFields = FilterFields;

export default PageList;
